import { PlayData } from "../../components/organisms/game/relations";
import { AbstractConnections } from "../abstracts/connections";
import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";

export class Connections extends ContractBaseModel<AbstractConnections> {
  getPageInfo() {
    return this.contract.getPageInfo().call(this.config);
  }

  getTimes() {
    return this.contract.getTimes().call(this.config);
  }

  getPrices() {
    return this.contract.getPrices().call(this.config);
  }

  getCustomerInfo(customer: string) {
    return this.contract.getCustomerInfo(customer).call(this.config);
  }

  getEmployeePlayTimestamp(employee: number) {
    return this.contract.getEmployeePlayTimestamp(employee).call(this.config);
  }

  getBuildTypeMultiplier(type: string) {
    return this.contract.getBuildTypeMultiplier(type).call(this.config);
  }

  getBuildTypeProbability(type: string) {
    return this.contract.getBuildTypeProbability(type).call(this.config);
  }

  getProbabilitiesTotal() {
    return this.contract.getProbabilitiesTotal().call(this.config);
  }

  //Add

  addModelType(model: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addModelType(model)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.ADD_MODEL_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.ADD_MODEL_ERROR, error, callback)
      );
  }

  addBuildType(
    type: string,
    probability: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .addBuildType(type, probability)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.ADD_TYPE_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.ADD_TYPE_ERROR, error, callback)
      );
  }

  removeModelType(
    model: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .removeModelType(model)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.REMOVE_MODEL_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.REMOVE_MODEL_ERROR, error, callback)
      );
  }

  removeBuildType(
    type: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .removeBuildType(type)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.REMOVE_TYPE_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.REMOVE_TYPE_ERROR, error, callback)
      );
  }

  //Setters
  //Generators
  //Questions

  //Mint

  mintFactory(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintFactory()
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mintEmployee(
    factory: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mintEmployee(factory)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  //Burn

  burnFactory(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burnFactory(id)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.BURN_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.BURN_ERROR, error, callback)
      );
  }

  burnEmployee(
    employee: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .burnEmployee(employee)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.BURN_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.BURN_ERROR, error, callback)
      );
  }

  //NFT Actions

  resetMaxFactoryDeploy(
    factory: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .resetMaxFactoryDeploy(factory)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(
          AppErrorCode.RESET_DEPLOYMENTS_ERROR,
          receipt,
          callback
        )
      )
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.RESET_DEPLOYMENTS_ERROR,
          error,
          callback
        )
      );
  }

  growFactory(factory: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .growFactory(factory)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.GROW_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.GROW_ERROR, error, callback)
      );
  }

  growAllFactories(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .growAllFactories()
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.GROW_ALL_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.GROW_ALL_ERROR, error, callback)
      );
  }

  playWithFactory(
    factory: number,
    employees: number[],
    callback: (error: AppErrorCode | null, playData: PlayData | null) => void
  ) {
    return this.contract
      .playWithFactory(factory, employees)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        if (receipt) {
          if (callback)
            callback(null, {
              win: Boolean(receipt.events.PlayRelations.returnValues[2]),
              random: Number(receipt.events.PlayRelations.returnValues[1]),
              amount: Number(receipt.events.PlayRelations.returnValues[3]),
              chemistry: Number(receipt.events.PlayRelations.returnValues[4]),
            });
        } else if (callback)
          callback(AppErrorCode.PLAY_WITH_FACTORY_ERROR, null);
      })
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.PLAY_WITH_FACTORY_ERROR,
          error,
          (error) => callback(error, null)
        )
      );
  }

  getEmployeeData(id: number) {
    return this.contract.getEmployeeData(id).call(this.config);
  }

  getFactoryData(id: number) {
    return this.contract.getFactoryData(id).call(this.config);
  }
}
