import { AnyAction } from "redux";
import { AppErrorCode } from "../../core/app";
import {
  Contract,
  ContractData,
  ContractsStateController,
} from "../../core/contracts";
import { UtilsHelpers } from "../../core/helpers/utils";
import { AppActions } from "../types";

export const loadContractAbi =
  (store: any) => (next: any) => (action: AnyAction) => {
    if (action.type === AppActions.LOAD_CONTRACT_ABI) {
      UtilsHelpers.debugger(
        "Execute contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      ContractsStateController.loadContractAbi(
        action.contract as Contract,
        (err: boolean, abiData: any) => {
          if (err || !abiData) {
            next({
              type: AppActions.SET_ERROR,
              error: {
                code: AppErrorCode.BAD_REQUEST,
                message:
                  "Bad request. Load contract ABI (" + action.contract + ")",
              },
            });
          } else {
            UtilsHelpers.debugger(
              "Load contract ABI (" + action.contract + ")"
            );

            next({ ...action, type: AppActions.SET_CONTRACT_ABI, abiData });
          }
        }
      );
    } else if (action.type === AppActions.LOAD_MULTIPLE_CONTRACT_ABI) {
      UtilsHelpers.debugger(
        "Execute contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      let promises: Promise<ContractData>[] = [];

      for (let i = 0; i < action.contracts?.length; i++) {
        promises.push(
          new Promise((res) => {
            ContractsStateController.loadContractAbi(
              action.contracts[i] as Contract,
              (err: boolean, abiData: any) => {
                if (err || !abiData) {
                  UtilsHelpers.debugger(
                    "Error, contract loader (" + action.contracts[i] + ")"
                  );

                  res({
                    contract: action.contracts[i],
                    data: null,
                  });
                } else {
                  UtilsHelpers.debugger(
                    "Try to load contract ABI (" + action.contracts[i] + ")"
                  );
                  res({
                    contract: action.contracts[i],
                    data: abiData,
                  });
                }
              }
            );
          })
        );
      }
      Promise.all(promises)
        .then((fillFilled: ContractData[]) => {
          let error = false;

          for (let i = 0; i < action.contracts?.length; i++) {
            if (!fillFilled[i].data || !fillFilled[i].contract) error = true;
          }

          if (error) {
            UtilsHelpers.debugger("Loading contracts error.");

            next({
              type: AppActions.SET_ERROR,
              error: {
                code: AppErrorCode.BAD_REQUEST,
                message: "Bad request. Load contracts ABI.",
              },
            });
          } else {
            UtilsHelpers.debugger("Loading contracts success.");

            next({
              type: AppActions.SET_MULTIPLE_CONTRACT_ABI,
              contracts: fillFilled,
            });
          }
        })
        .catch(() => {
          next({
            type: AppActions.SET_ERROR,
            error: {
              code: AppErrorCode.BAD_REQUEST,
              message:
                "Bad request. Load contract ABI (" + action.contract + ")",
            },
          });
        });
    } else next(action);
  };
