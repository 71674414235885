import { EmployeeNFT } from "../../../core/employee";
import { UtilsHelpers } from "../../../core/helpers/utils";
import { FactoryNFT } from "../../../core/nft";

import "./main.styles.css";

interface RelationsRewardsProps {
  selectedEmployees: EmployeeNFT[];
  selectedFactory: FactoryNFT;
  onPlay: () => void;
}

export const RelationsRewards = (props: RelationsRewardsProps) => {
  const maxFactor = props.selectedEmployees.length * 4;
  let totalMultipliers = props.selectedFactory.multiplier || 0;
  let partFactor = 0;

  for (let i = 0; i < props.selectedEmployees.length; i++) {
    for (let j = 0; j < props.selectedEmployees[i].parts.length; j++) {
      if (props.selectedEmployees[i].parts[j] === props.selectedFactory.type) {
        partFactor++;
      }
    }

    totalMultipliers += props.selectedEmployees[i].multiplier;
  }

  return (
    <div className="ct-relations-rewards">
      <h1>
        Rewards.{" "}
        <span>
          <small>
            Your employees need{" "}
            {UtilsHelpers.getTypeName(props.selectedFactory.type)} parts.
          </small>
          <small>
            You need {UtilsHelpers.getTypeName(props.selectedFactory.type)}{" "}
            contracts.
          </small>
        </span>
      </h1>

      <h2>
        Probability to win: {partFactor + " / " + maxFactor} (
        {(partFactor * 100) / maxFactor}
        %)
      </h2>
      <h3>
        Your employees have {partFactor}{" "}
        {UtilsHelpers.getTypeName(props.selectedFactory.type)} parts
      </h3>
      <h3>Total Selected parts {maxFactor}</h3>
      <h2>Total rewards: {totalMultipliers}</h2>
      {props.selectedEmployees.length > 0 ? (
        <button className="ct-main-button" onClick={() => props.onPlay()}>
          Play
        </button>
      ) : (
        <div className="ct-select-employees">
          <p>Select more employees to play</p>
        </div>
      )}
    </div>
  );
};
