import { EmployeeNFT } from "../../../core/employee";
import { FactoryNFT } from "../../../core/nft";
import { AppActions } from "../../types";

export const gameChangeSelectedFactory = (factory: FactoryNFT | undefined) => {
  return { type: AppActions.GAME_CHANGE_SELECTED_FACTORY, factory };
};

export const gameResetEmployeeSelection = () => {
  return { type: AppActions.GAME_RESET_EMPLOYEE_SELECTION };
};

export const gameRemoveEmployeeFromSelection = (employee: EmployeeNFT) => {
  return { type: AppActions.GAME_REMOVE_EMPLOYEE_FROM_SELECTION, employee };
};

export const gameAddEmployeeToSelection = (employee: EmployeeNFT) => {
  return { type: AppActions.GAME_ADD_EMPLOYEE_TO_SELECTION, employee };
};
