import { Link } from "react-router-dom";
import { navigationLinks } from "../../../core/app";
import "./left.styles.css";

interface LeftNavigationProps {
  open: boolean;
  onToggleLeftNavigation: () => void;
}

export const LeftNavigation = (props: LeftNavigationProps) => {
  return (
    <div className={"ct-left-nav" + (props.open ? " ct-open" : "")}>
      {navigationLinks.map((parent, index) => {
        return (
          <div key={index} className="ct-link-parent">
            <span>{parent.display}</span>
            <div className="ct-parent-links">
              {parent.links?.map((link, index) => {
                if (link.type === "internal") {
                  return (
                    <Link
                      key={index}
                      onClick={() => props.onToggleLeftNavigation()}
                      to={link.href}
                    >
                      {link.display}
                    </Link>
                  );
                } else {
                  return (
                    <a
                      key={index}
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.display}
                    </a>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
