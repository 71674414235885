import { ContractBaseModel } from "../abstracts/contract";
import { AbstractToken } from "../abstracts/token";
import { AppErrorCode } from "../app";
import { UtilsHelpers } from "../helpers/utils";

export class Token extends ContractBaseModel<AbstractToken> {
  name() {
    return "";
  }

  symbol() {
    return "";
  }

  decimals() {
    return 1;
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  balanceOf(account: string) {
    return this.contract.balanceOf(account).call(this.config);
  }

  transfer(recipient: string, amount: number) {
    return false;
  }

  allowance(owner: string, spender: string) {
    return this.contract.allowance(owner, spender).call(this.config);
  }

  approve(spender: string, amount: string) {
    return this.contract.approve(spender, amount).send(this.config);
  }

  transferFrom(sender: string, recipient: string, amount: string) {
    return this.contract
      .transferFrom(sender, recipient, amount)
      .send(this.config);
  }

  increaseAllowance(spender: string, addedValue: number) {
    return false;
  }

  decreaseAllowance(spender: string, subtractedValue: number) {
    return false;
  }

  requestTestnetTokens(callback: (error: AppErrorCode | null) => void) {
    this.contract
      .requestTestnetTokens()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        if (receipt) {
          UtilsHelpers.debugger(
            "Testnet request success (" + receipt.gasUsed + ")"
          );
          if (callback) callback(null);
        } else {
          UtilsHelpers.debugger(
            "Testnet request Error (" + receipt.gasUsed + ")"
          );
          if (callback) callback(AppErrorCode.INVALID_TESTNET_REQUEST);
        }
      })
      .on("error", (error: any) => {
        if (error.code === 4001) {
          if (callback) callback(AppErrorCode.USER_DENIED_TRANSACTION);
        } else {
          if (callback) callback(AppErrorCode.INVALID_TESTNET_REQUEST);
        }
      });
  }

  canMakeTestnetRequest() {
    return this.contract
      .canMakeTestnetRequest(this.config.from)
      .call(this.config);
  }
}
