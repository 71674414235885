import React from "react";

import { ContractsState } from "../../storage/state/contracts/state";
import { AppErrorCode, posibleChains } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { FactoryNFT } from "../../core/nft";
import { BuilderFactoryGrid } from "../organisms/builder/grid";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { BuilderActions } from "../organisms/builder/actions";

interface ProductionComponentProps {
  onToggleLoader: (force: boolean) => void;
  onChangeFactoriesPagination: (page: number) => void;
  onLoadCustomerData: () => void;
  onLoadBlockChain: (clicked: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
  blockChain: BlockChainState;
  contracts: ContractsState;
  factoriesPagination: number;
}

export class Production extends React.Component<ProductionComponentProps> {
  async onGrow(nft: number | undefined) {
    if (this.props.blockChain.controller && typeof nft === "number") {
      this.props.onToggleLoader(true);
      FactoryNFT.growNFT(
        this.props.blockChain.controller,
        nft,
        (error: AppErrorCode | null) => {
          if (error) this.props.onSetBlockChainError(AppErrorCode.GROW_ERROR);
          else this.props.onLoadCustomerData();
        }
      );
    } else this.props.onSetBlockChainError(AppErrorCode.CANT_GROW_ERROR);
  }

  async onGrowAll() {
    if (this.props.blockChain.controller) {
      this.props.onToggleLoader(true);
      FactoryNFT.growAllNFT(
        this.props.blockChain.controller,
        (error: AppErrorCode | null) => {
          if (error) this.props.onSetBlockChainError(AppErrorCode.GROW_ERROR);
          else this.props.onLoadCustomerData();
        }
      );
    } else this.props.onSetBlockChainError(AppErrorCode.CANT_GROW_ERROR);
  }

  render() {
    return (
      <div className="ct-app-container">
        <div className="ct-data-container ct-max-container">
          <BuilderActions
            disableBuy={true}
            onGetINV={async () => {
              let provider = BlockChainHelpers.getProvider();
              if (
                (BlockChainHelpers.getAppChain().chainId ===
                  posibleChains["bsc-test"].chainId ||
                  BlockChainHelpers.getAppChain().chainId ===
                    posibleChains["ganache"].chainId) &&
                this.props.blockChain.controller?.selectedAccount &&
                provider
              ) {
                this.props.blockChain.controller?.token?.transferFrom(
                  this.props.blockChain.controller?.token.address,
                  this.props.blockChain.controller?.selectedAccount,
                  provider.utils.toWei("10000000", "ether")
                );
              }
            }}
            onGrowAll={() => this.onGrowAll()}
            growAllAmount={this.props.blockChain.customer?.growAllRewards}
            canBuildNewNFT={
              this.props.blockChain.customer?.canMintNewFactory || false
            }
            factoryNftPrice={this.props.blockChain.customer?.factoryPrice}
            employeeNftPrice={this.props.blockChain.customer?.employeePrice}
          />
          {this.props.blockChain.customer?.factoryNFTArray?.length ? (
            <BuilderFactoryGrid
              customerBalance={this.props.blockChain.customer?.tokenBalance}
              factoryNftPrice={this.props.blockChain.customer?.factoryPrice}
              employeeNFTPrice={this.props.blockChain.customer?.employeePrice}
              tokenAddress={this.props.blockChain.controller?.token?.address}
              filter={{ type: null }}
              factoryNfts={this.props.blockChain.customer?.factoryNFTArray}
              onChangeFactoriesPage={(page: number) =>
                this.props.onChangeFactoriesPagination(page)
              }
              page={this.props.factoriesPagination}
              nftsPerPage={8}
              onGrow={(id: number) => this.onGrow(id)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
