import Web3 from "web3";
import { AppErrorCode } from "../app";

export class ContractBaseModel<T> {
  public key: string = "";
  public contract: T;
  public address: string;

  constructor(
    private contractData: any,
    protected web3: Web3,
    protected selectedAccount: string
  ) {
    this.contract = this.contractData.methods;
    this.address = this.contractData._address;
  }

  get config() {
    return { from: this.selectedAccount };
  }

  errorCallback(
    defaultError: AppErrorCode,
    response: any,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (response.code === 4001) {
      if (callback) callback(AppErrorCode.USER_DENIED_TRANSACTION);
    } else if (callback) callback(defaultError);
  }

  defaultReceipt(
    defaultError: AppErrorCode,
    receipt: any,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (receipt) {
      if (callback) callback(null);
    } else if (callback) callback(defaultError);
  }
}
