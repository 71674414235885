import { ContractsState } from "../storage/state/contracts/state";
import { UtilsHelpers } from "./helpers/utils";

export enum Contract {
  CONNECTIONS = "Connections",
  TOKEN = "Token",
  FACTORIES = "Factories",
  EMPLOYEES = "Employees",
}

export interface NormalizedABI {
  networks: ABINetworks;
  [property: string]: any;
}

export type ABINetworkData = {
  events: any;
  links: any;
  address: string;
  transactionHash: string;
};

export type ABINetworks = {
  [id: string]: ABINetworkData;
};

export type ContractData = {
  data: NormalizedABI | null;
  contract: Contract;
};

export class ContractsStateController {
  static contractBasePath: string = "/assets/contracts/";

  static contractPaths: { [key: string]: string } = {
    [Contract.CONNECTIONS]:
      ContractsStateController.contractBasePath + "Connections.json",
    [Contract.TOKEN]: ContractsStateController.contractBasePath + "Token.json",
    [Contract.FACTORIES]:
      ContractsStateController.contractBasePath + "Factories.json",
    [Contract.EMPLOYEES]:
      ContractsStateController.contractBasePath + "Employees.json",
  };

  public static async loadContractAbiMock(
    path: string,
    callback?: (error: boolean, abiData?: any) => void
  ) {
    fetch(path)
      .then((result) => result.json())
      .then((json: any) => {
        return callback && json
          ? callback(false, json)
          : callback
          ? callback(true)
          : null;
      })
      .catch((err) => {
        UtilsHelpers.debugger(err?.code + " " + path);
        return callback ? callback(true) : null;
      });
  }

  public static loadContractAbi(
    contract: Contract,
    callback?: (error: boolean, abiData?: any) => void
  ) {
    return ContractsStateController.loadContractAbiMock(
      ContractsStateController.contractPaths[contract],
      callback
    );
  }

  public static isLoaded(state: ContractsState) {
    let isValid = true;
    let keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
      if (state[keys[i] as Contract] === null) isValid = false;
    }

    return isValid;
  }

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  public static setContractAbi(
    state: ContractsState,
    contract: string,
    abiData: any
  ): ContractsState {
    return {
      ...state,
      [contract]: { ...state[contract as Contract], data: abiData },
    };
  }

  public static setMultipleContractsAbi(
    state: ContractsState,
    contracts: ContractData[]
  ): ContractsState {
    let obj: ContractsState = {};

    for (let i = 0; i < contracts.length; i++) {
      obj[contracts[i].contract as Contract] = contracts[i];
    }

    return { ...state, ...obj };
  }

  /* -------------------------------------------------------------------------- */
}
