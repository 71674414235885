import { AppActions } from "../../types";

export const toggleLeftNavigation = (force?: boolean) => {
  return { type: AppActions.TOGGLE_LEFT_NAVIGATION, force };
};

export const toggleLoader = (force?: boolean, pageLoad?: boolean) => {
  return { type: AppActions.TOGGLE_LOADER, force, pageLoad };
};

export const changeFactoriesPagination = (page: number) => {
  return { type: AppActions.CHANGE_FACTORIES_PAGINATION, page };
};

export const changeEmployeesPagination = (page: number) => {
  return { type: AppActions.CHANGE_EMPLOYEES_PAGINATION, page };
};
