import React from "react";
import { connect } from "react-redux";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Contract, ContractsStateController } from "../core/contracts";
import { loadMultipleContractAbis } from "../storage/state/contracts/actions";
import { AppReducer, AppState } from "../storage/types";
import { BlockChain } from "../core/chain";

import {
  destroyBlockChainController,
  saveBlockChainController,
  setBlockChainError,
  setCustomer,
} from "../storage/state/blockChain/actions";

import {
  toggleLeftNavigation,
  toggleLoader,
  changeFactoriesPagination,
  changeEmployeesPagination,
} from "../storage/state/app/actions";

import {
  gameChangeSelectedFactory,
  gameAddEmployeeToSelection,
  gameRemoveEmployeeFromSelection,
  gameResetEmployeeSelection,
} from "../storage/state/game/actions";

import { ContractsState } from "../storage/state/contracts/state";
import { AppErrorCode } from "../core/app";
import { BlockChainState } from "../storage/state/blockChain/state";
import { NavWindow } from "./types";
import { BlockChainHelpers } from "../core/helpers/chain";
import { Customer } from "../core/customer";
import { UtilsHelpers } from "../core/helpers/utils";
import { ErrorComponent } from "./molecules/error";
import { TopNavigation } from "./organisms/navigation/top";
import { LeftNavigation } from "./organisms/navigation/left";
import { ApplicationState } from "../storage/state/app/state";
import { BuilderPage } from "./pages/builder";
import { Loader } from "./organisms/loader";
import { Production } from "./pages/production";
import { Footer } from "./organisms/navigation/footer";
import { RelationsGamePage } from "./pages/relations";
import { RelationsGameState } from "../storage/state/game/state";
import { FactoryNFT } from "../core/nft";
import { EmployeeNFT } from "../core/employee";

interface AppComponentProps {
  loadMultipleContracts: (contracts: Contract[]) => void;
  saveBlockChainData: (controller: BlockChain) => void;
  destroyBlockChain: () => void;
  setBlockChainError: (error: AppErrorCode | null) => void;
  setCustomer: (customer: Customer) => void;
  toggleLeftNavigation: (force?: boolean) => void;
  toggleLoader: (force?: boolean, pageLoad?: boolean) => void;
  changeFactoriesPagination: (page: number) => void;
  changeEmployeesPagination: (page: number) => void;
  gameChangeSelectedFactory: (factory: FactoryNFT | undefined) => void;
  gameAddSelectedEmployee: (employee: EmployeeNFT) => void;
  gameResetEmployeeSelection: () => void;
  gameRemoveEmployeeFromSelection: (employee: EmployeeNFT) => void;
  relationsGameState: RelationsGameState;
  appState: ApplicationState;
  contracts: ContractsState;
  blockChain: BlockChainState;
}

const mapStateToProps = (state: AppState) => {
  return {
    appState: state[AppReducer.APP],
    relationsGameState: state[AppReducer.RELATIONS_GFAME],
    contracts: state[AppReducer.CONTRACTS],
    blockChain: state[AppReducer.BLOCK_CHAIN] as BlockChainState,
  };
};

const mapDispatchToProps = {
  loadMultipleContracts: loadMultipleContractAbis,
  saveBlockChainData: saveBlockChainController,
  destroyBlockChain: destroyBlockChainController,
  changeFactoriesPagination,
  changeEmployeesPagination,
  setBlockChainError,
  setCustomer,
  toggleLeftNavigation,
  toggleLoader,
  gameChangeSelectedFactory,
  gameResetEmployeeSelection,
  gameAddSelectedEmployee: gameAddEmployeeToSelection,
  gameRemoveEmployeeFromSelection,
};

export class App extends React.Component<AppComponentProps> {
  componentDidMount() {
    this.props.toggleLoader(true);

    if (this.props.loadMultipleContracts) {
      const windowParse = window as NavWindow;

      if (windowParse.ethereum) {
        windowParse.ethereum.on("chainChanged", () => {
          this._reloadBlockChain();
        });

        windowParse.ethereum.on("accountsChanged", () => {
          this._reloadBlockChain();
        });

        if (!ContractsStateController.isLoaded(this.props.contracts)) {
          this.props.loadMultipleContracts([
            Contract.CONNECTIONS,
            Contract.TOKEN,
            Contract.FACTORIES,
            Contract.EMPLOYEES,
          ]);
        }
      } else {
        this.props.setBlockChainError(AppErrorCode.INVALID_PROVIDER);
        UtilsHelpers.debugger("[APP] Error, Invalid provider.");
      }
    } else UtilsHelpers.debugger("[APP] Error, contracts loaders.");
  }

  private _reloadBlockChain() {
    this.props.destroyBlockChain();
    this.loadBlockChain();
  }

  componentDidUpdate(prevProps: AppComponentProps) {
    if (
      ContractsStateController.isLoaded(this.props.contracts) &&
      !ContractsStateController.isLoaded(prevProps.contracts)
    ) {
      this.loadBlockChain();
    }

    if (
      ContractsStateController.isLoaded(this.props.contracts) &&
      !prevProps.blockChain.controller &&
      this.props.blockChain.controller
    ) {
      this.loadCustomerData();
    }

    if (!!this.props.blockChain.error && this.props.appState.loader) {
      this.props.toggleLoader(false);
    }
  }

  async changeToAppNetwork() {
    await BlockChainHelpers.reloadOrChangeNetwork();
  }

  loadBlockChain() {
    if (this.props.contracts.Connections && !this.props.blockChain.error) {
      let blockChain = new BlockChain();

      blockChain.principalListener.on(
        AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
        () => {
          this.props.setBlockChainError(
            AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK
          );
        }
      );

      blockChain.loadBlockChainData(
        this.props.contracts,
        async (err: AppErrorCode | null) => {
          if (err) this.props.setBlockChainError(err);
          else if (this.props.saveBlockChainData)
            this.props.saveBlockChainData(blockChain);
        }
      );
    }
  }

  async loadCustomerData() {
    console.log("try to load customer");
    if (this.props.blockChain.controller) {
      let { customer, error } = await Customer.loadCustomerData(
        this.props.blockChain.controller
      );
      if (customer && !error) this.props.setCustomer(customer);
      else this.props.setBlockChainError(error);
      setTimeout(() => this.props.toggleLoader(false), 1000);
    }
  }

  async approveTokenSpend() {
    if (this.props.blockChain.controller) {
      await Customer.approveTokenSpend(this.props.blockChain.controller);
      this._reloadBlockChain();
    }
  }

  componentWillUnmount() {
    this.props.destroyBlockChain();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="ct-app-container">
          <Loader open={this.props.appState.loader} />
          <TopNavigation
            onLoadCustomerData={() => this.loadCustomerData()}
            onToggleLeftNav={() => this.props.toggleLeftNavigation()}
            onLoadBlockChain={() => this.loadBlockChain()}
            tokenBalance={this.props.blockChain.customer?.tokenBalance}
            walletAddress={this.props.blockChain.controller?.selectedAccount}
            tokenAddress={this.props.blockChain.controller?.token?.address}
            restorePageLoading={this.props.appState.restorePageLoad}
            onToggleLoader={(force: boolean, pageLoad: boolean) =>
              this.props.toggleLoader(force, pageLoad)
            }
          ></TopNavigation>
          <LeftNavigation
            onToggleLeftNavigation={() => this.props.toggleLeftNavigation()}
            open={this.props.appState.leftNavigation}
          ></LeftNavigation>
          {this.props.blockChain.error !== null ? (
            <ErrorComponent
              onRemoveError={() => this.props.setBlockChainError(null)}
              code={this.props.blockChain.error}
              onApproveTokenSpend={() => this.approveTokenSpend()}
              onChangeNetwork={() => {
                this.props.setBlockChainError(null);
                this.props.toggleLoader(true);
                this.changeToAppNetwork();
              }}
            />
          ) : (
            ""
          )}
          {this.props.blockChain.controller && this.props.contracts ? (
            <Switch>
              <Route exact path="/">
                <BuilderPage
                  onChangeFactoriesPagination={(page: number) =>
                    this.props.changeFactoriesPagination(page)
                  }
                  onChangeEmployeesPagination={(page: number) =>
                    this.props.changeEmployeesPagination(page)
                  }
                  factoriesPagination={this.props.appState.factoriesPagination}
                  employeesPagination={this.props.appState.employeesPagination}
                  active={true}
                  onToggleLoader={(force: boolean) =>
                    this.props.toggleLoader(force)
                  }
                  onLoadBlockChain={() => {
                    this.loadBlockChain();
                  }}
                  onSetBlockChainError={(error: AppErrorCode) => {
                    this.props.setBlockChainError(error);
                  }}
                  onLoadCustomerData={() => this.loadCustomerData()}
                  contracts={this.props.contracts}
                  blockChain={this.props.blockChain}
                />
              </Route>
              <Route exact path="/production">
                <Production
                  onChangeFactoriesPagination={(page: number) =>
                    this.props.changeFactoriesPagination(page)
                  }
                  factoriesPagination={this.props.appState.factoriesPagination}
                  onToggleLoader={(force: boolean) =>
                    this.props.toggleLoader(force)
                  }
                  onLoadBlockChain={() => {
                    this.loadBlockChain();
                  }}
                  onSetBlockChainError={(error: AppErrorCode) => {
                    this.props.setBlockChainError(error);
                  }}
                  onLoadCustomerData={() => this.loadCustomerData()}
                  contracts={this.props.contracts}
                  blockChain={this.props.blockChain}
                />
              </Route>
              <Route exact path="/builder">
                <BuilderPage
                  onChangeFactoriesPagination={(page: number) =>
                    this.props.changeFactoriesPagination(page)
                  }
                  onChangeEmployeesPagination={(page: number) =>
                    this.props.changeEmployeesPagination(page)
                  }
                  factoriesPagination={this.props.appState.factoriesPagination}
                  employeesPagination={this.props.appState.employeesPagination}
                  active={true}
                  onToggleLoader={(force: boolean) =>
                    this.props.toggleLoader(force)
                  }
                  onLoadBlockChain={() => {
                    this.loadBlockChain();
                  }}
                  onSetBlockChainError={(error: AppErrorCode) => {
                    this.props.setBlockChainError(error);
                  }}
                  onLoadCustomerData={() => this.loadCustomerData()}
                  contracts={this.props.contracts}
                  blockChain={this.props.blockChain}
                />
              </Route>
              <Route exact path="/relations-game">
                {this.props.blockChain.customer ? (
                  <RelationsGamePage
                    gameChangeSelectedFactory={(
                      factory: FactoryNFT | undefined
                    ) => this.props.gameChangeSelectedFactory(factory)}
                    gameAddSelectedEmployee={(employee: EmployeeNFT) =>
                      this.props.gameAddSelectedEmployee(employee)
                    }
                    onToggleLoader={(force: boolean) =>
                      this.props.toggleLoader(force)
                    }
                    onSetBlockChainError={(error: AppErrorCode | null) => {
                      this.props.setBlockChainError(error);
                    }}
                    onLoadCustomerData={() => this.loadCustomerData()}
                    gameResetEmployeeSelection={() =>
                      this.props.gameResetEmployeeSelection()
                    }
                    gameRemoveEmployeeFromSelection={(employee: EmployeeNFT) =>
                      this.props.gameRemoveEmployeeFromSelection(employee)
                    }
                    relationsGameState={this.props.relationsGameState}
                    blockChain={this.props.blockChain}
                  />
                ) : (
                  ""
                )}
              </Route>
            </Switch>
          ) : (
            <ErrorComponent
              onRemoveError={() => this.props.setBlockChainError(null)}
              onChangeNetwork={() => {
                this.props.setBlockChainError(null);
                this.props.toggleLoader(true);
                this.changeToAppNetwork();
              }}
              code={
                this.props.blockChain.error
                  ? this.props.blockChain.error
                  : AppErrorCode.INVALID_CONTRACT_LOADING
              }
            />
          )}
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
