import { useState } from "react";
import { UtilsHelpers } from "../../../core/helpers/utils";
import { FactoryNFT } from "../../../core/nft";
import { StaticCityNFT } from "../../molecules/nft/city";

import "./main.styles.css";

export interface FactorySelectorProps {
  selectedFactory: FactoryNFT | undefined;
  factories: FactoryNFT[];
  gameChangeSelectedFactory: (factory: undefined | FactoryNFT) => void;
}

export const FactorySelector = (props: FactorySelectorProps) => {
  const [showSelector, toggleShowSelector] = useState(false);

  return (
    <div className="ct-factory-selector-container ct-max-container">
      <h2>Choose your Factory</h2>
      <div className="ct-factory-selector">
        {props.selectedFactory ? (
          <div className="ct-selected-factory">
            <StaticCityNFT factoryNft={props.selectedFactory} />
            <div className="ct-explanation">
              <h3>
                You need {UtilsHelpers.getTypeName(props.selectedFactory.type)}{" "}
                contracts
              </h3>
              <p>
                Try to get the best chemistry with{" "}
                {UtilsHelpers.getTypeName(props.selectedFactory.type)}{" "}
                employees. If your employees has{" "}
                {UtilsHelpers.getTypeName(props.selectedFactory.type)} parts you
                get better chance to win{" "}
                {UtilsHelpers.getTypeName(props.selectedFactory.type)}{" "}
                contracts.
              </p>
              <h4>Multiplier: {props.selectedFactory.multiplier}</h4>
            </div>
          </div>
        ) : (
          <div className="ct-select-factory">
            <h4>Select your factory and try to win contracts</h4>
          </div>
        )}
        <div className="ct-selector-actions">
          <div
            className="ct-selector-toggler"
            onClick={() => toggleShowSelector(!showSelector)}
          >
            {showSelector ? (
              <span>
                <span className="fas fa-arrow-up"></span> Close factories
              </span>
            ) : (
              <span className="ct-load-factories">
                <span className="fas fa-arrow-down"></span> Load Factories
              </span>
            )}
          </div>
          {props.selectedFactory ? (
            <div
              className="ct-selector-remove"
              onClick={() => props.gameChangeSelectedFactory(undefined)}
            >
              <span className="fas fa-times"></span> Remove
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {showSelector ? <small>Click on factory to select them</small> : ""}
      {showSelector ? (
        <div className="ct-posible-factories">
          {props.factories.map((nft: FactoryNFT, index) => (
            <StaticCityNFT
              key={index}
              onNFTClick={() =>
                nft.canPlay ? props.gameChangeSelectedFactory(nft) : null
              }
              className={
                (nft?.canPlay ? "ct-clickeable" : "ct-invalid") +
                (props.selectedFactory?.id === nft.id ? " ct-clicked" : "")
              }
              small
              factoryNft={nft}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
