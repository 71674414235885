import { BlockChainState } from "../storage/state/blockChain/state";
import { appConfig, AppErrorCode, AppMode } from "./app";
import { BlockChain } from "./chain";
import { EmployeeNFT } from "./employee";
import { BlockChainHelpers } from "./helpers/chain";
import { UtilsHelpers } from "./helpers/utils";
import { FactoryNFT } from "./nft";
import { AppData } from "./types";

export class Customer {
  constructor(
    public _address: string | null,
    private _factoryNfts: FactoryNFT[],
    private _employeeNfts: EmployeeNFT[],
    public appDataModel: AppData
  ) {}

  get totalNFTs() {
    return this._factoryNfts.length;
  }

  get tokenBalance() {
    return this.appDataModel.customerInfo.tokenBalance;
  }

  get canMakeTestnetRequest() {
    return this.appDataModel.customerInfo.canMakeTestnetRequest;
  }

  get factoryPrice() {
    return this.appDataModel.pricesInfo.factoryPrice;
  }

  get employeePrice() {
    return this.appDataModel.pricesInfo.employeePrice;
  }

  get factoryNFTArray() {
    return this._factoryNfts;
  }

  get employeeNFTArray() {
    return this._employeeNfts;
  }

  get canMintNewFactory() {
    return (
      this.appDataModel.customerInfo.tokenBalance >=
      this.appDataModel.pricesInfo.factoryPrice
    );
  }

  get canMintNewEmployee() {
    return (
      this.appDataModel.customerInfo.tokenBalance >=
      this.appDataModel.pricesInfo.employeePrice
    );
  }

  get growAllRewards() {
    let rewards = 0;

    for (let i = 0; i < this._factoryNfts.length; i++) {
      if (this._factoryNfts[i].canGrow()) {
        rewards += this._factoryNfts[i].growReward;
      }
    }

    return rewards;
  }

  //BlockChain

  static async loadCustomerData(blockChain: BlockChain): Promise<{
    customer: null | Customer;
    error: AppErrorCode | null;
  }> {
    UtilsHelpers.debugger("Load customer Data.");

    if (
      blockChain.token &&
      blockChain.connections &&
      blockChain.selectedAccount
    ) {
      const approvedSpend = await Customer.getApprovedSpend(blockChain);

      if (approvedSpend > 0) {
        const pageInfo = await blockChain.connections.getPageInfo();
        const pricesInfo = await blockChain.connections.getPrices();
        const timesInfo = await blockChain.connections.getTimes();

        const customerInfo = await blockChain.connections.getCustomerInfo(
          blockChain.selectedAccount
        );

        let canMakeTestnetRequest =
          appConfig.mode === AppMode.PRO
            ? false
            : await blockChain.token.canMakeTestnetRequest();

        let tokenBalance = await blockChain.token.balanceOf(
          blockChain.selectedAccount
        );

        UtilsHelpers.debugger(
          "Load customer balance (" +
            UtilsHelpers.normalizeWei(tokenBalance) +
            ")."
        );

        const appDataModel: AppData = AppData.buildFromTemplates(
          pageInfo,
          pricesInfo,
          timesInfo,
          customerInfo,
          canMakeTestnetRequest,
          tokenBalance
        );

        let parsedFactories: FactoryNFT[] = [];
        let parsedEmployees: any[] = [];

        for (let i = 0; i < appDataModel.customerInfo.factories?.length; i++) {
          let factory = await blockChain.connections.getFactoryData(
            Number(appDataModel.customerInfo.factories[i])
          );

          parsedFactories.push(
            FactoryNFT.createWithContractTemplate(
              {
                id: Number(appDataModel.customerInfo.factories[i]),
                ...factory,
              },
              appDataModel
            )
          );
        }

        for (let i = 0; i < appDataModel.customerInfo.employees?.length; i++) {
          let employee = await blockChain.connections.getEmployeeData(
            Number(appDataModel.customerInfo.employees[i])
          );

          parsedEmployees.push(
            EmployeeNFT.createWithContractTemplate(
              {
                id: appDataModel.customerInfo.employees[i],
                ...employee,
              },
              appDataModel
            )
          );
        }

        UtilsHelpers.debugger(
          "Load customer tokens (" + parsedFactories.length + ")."
        );

        await Customer.approveTokenSpend(blockChain);

        return {
          customer: new Customer(
            blockChain.selectedAccount,
            parsedFactories,
            parsedEmployees,
            appDataModel
          ),
          error: null,
        };
      } else {
        return { customer: null, error: AppErrorCode.INVALID_APPROVED_SPEND };
      }
    } else {
      UtilsHelpers.debugger("Invalid data to load customer.");
      return { error: AppErrorCode.INVALID_CONTRACT_LOADING, customer: null };
    }
  }

  static async approveTokenSpend(blockChain: BlockChain) {
    if (
      blockChain &&
      blockChain.token &&
      blockChain.selectedAccount &&
      blockChain.connections
    ) {
      let approvedSpend = await Customer.getApprovedSpend(blockChain);

      UtilsHelpers.debugger("Approved spend. (" + approvedSpend + ")");

      if (approvedSpend === 0) {
        let amount = BlockChainHelpers.getProvider()?.utils.toWei(
          "100000000",
          "ether"
        );
        await blockChain.token.approve(
          blockChain.connections.address,
          amount || "0"
        );
      }
    }
  }

  static async getApprovedSpend(blockChain: BlockChain) {
    if (
      blockChain &&
      blockChain.token &&
      blockChain.selectedAccount &&
      blockChain.connections
    ) {
      return Number(
        await blockChain.token.allowance(
          blockChain.selectedAccount,
          blockChain.connections.address
        )
      );
    } else return 0;
  }

  //Storage

  static setCustomer(state: BlockChainState, customer: Customer) {
    return { ...state, customer };
  }

  static clearCustomer(state: BlockChainState) {
    return { ...state, customer: null };
  }
}
