import Web3 from "web3";
import EventEmitter from "events";

import { NavWindow } from "../components/types";
import { BlockChainState } from "../storage/state/blockChain/state";
import { ContractsState } from "../storage/state/contracts/state";
import { ABINetworkData, Contract, ContractData } from "./contracts";
import { BlockChainHelpers } from "./helpers/chain";
import { AppErrorCode, ULTRA_ERRORS } from "./app";
import { UtilsHelpers } from "./helpers/utils";
import { Connections } from "./contracts/connections";
import { Factories } from "./contracts/factories";
import { Token } from "./contracts/token";
import { Employees } from "./contracts/employees";

export interface BlockChainData {
  accounts: string[];
  chainId: number | null;
}

export interface RelatedContracts {
  [Contract.CONNECTIONS]: any;
  [Contract.TOKEN]: any;
  [Contract.FACTORIES]: any;
  [Contract.EMPLOYEES]: any;
}

export interface RelatedContractsInstances {
  [Contract.CONNECTIONS]: Connections | null;
  [Contract.TOKEN]: Token | null;
  [Contract.FACTORIES]: Factories | null;
  [Contract.EMPLOYEES]: Employees | null;
}

export enum BlockChainEvent {
  LOAD_CONTRACT = "load-contract",
  LOAD_CONTRACT_ERROR = "load-contract-error",
  END_CONTRACT_LOADING = "end-contract-loading",
  CHANGE_NETWORK = "change-network",
}

export enum BlockChainErrorEvent {}

export class SmartContract {}

export class BlockChain {
  private _accounts: string[] = [];
  private _provider: Web3 | null = null;
  private _chainId: number | null = null;
  private _contractsData: ContractsState | null = null;
  private _contractKeys: string[] = [];
  private _web3: Web3 | null = null;

  public buildTypes: string[] | null = null;
  public buildModels: string[] | null = null;

  private _contracts: RelatedContracts = {
    [Contract.CONNECTIONS]: null,
    [Contract.TOKEN]: null,
    [Contract.FACTORIES]: null,
    [Contract.EMPLOYEES]: null,
  };

  private _contractsInstance: RelatedContractsInstances = {
    [Contract.CONNECTIONS]: null,
    [Contract.TOKEN]: null,
    [Contract.FACTORIES]: null,
    [Contract.EMPLOYEES]: null,
  };

  // Subscriptions
  principalListener: EventEmitter = new EventEmitter();

  /* -------------------------------------------------------------------------- */
  /*                           ANCHOR Contract Loading                          */
  /* -------------------------------------------------------------------------- */

  async loadBlockChainData(
    contractsData: ContractsState,
    callback?: (err: AppErrorCode | null, blockChain?: BlockChain) => void
  ) {
    if (this._contractsStateIsValid(contractsData)) {
      if (await BlockChainHelpers.loadWeb3()) {
        this._provider = BlockChainHelpers.getProvider();
        UtilsHelpers.debugger("Web3 is loaded.");

        if (!!this._provider) {
          UtilsHelpers.debugger("Provider is loaded.");

          let error: AppErrorCode | null = null;

          this._contractsData = contractsData;
          this._accounts = await this._provider.eth.requestAccounts();
          this._chainId = await this._provider.eth.getChainId();
          this._web3 = (window as NavWindow).web3 as Web3;
          this._contractKeys = Object.keys(this._contractsData);

          UtilsHelpers.debugger(
            "BlockChain Connection\n" +
              "   Selected Account: " +
              this.selectedAccount +
              "\n   Chain ID: " +
              this._chainId
          );

          // Load all contracts

          if (this._contractKeys.length > 0) {
            for (let i = 0; i < this._contractKeys.length; i++) {
              let contractName: Contract = this._contractKeys[i] as Contract;

              UtilsHelpers.debugger(
                "Search contract data (" + contractName + ")."
              );

              if (this._contractsData[contractName]) {
                UtilsHelpers.debugger(
                  "Loading contract (" + this._contractKeys[i] + ")"
                );

                let contractData =
                  this._contractsData[this._contractKeys[i] as Contract];

                if (contractData) {
                  let contractLoading: AppErrorCode | null =
                    await this._loadContract(contractData);

                  if (contractLoading !== null) {
                    error = contractLoading;

                    UtilsHelpers.debugger(
                      "Contract cannot be loading (" + error + ")."
                    );

                    break;
                  } else {
                    UtilsHelpers.debugger(
                      "Contract is loaded (" + contractData.contract + ")"
                    );
                  }
                } else UtilsHelpers.debugger("Contract data is not valid.");
              }
            }
          } else {
            UtilsHelpers.debugger("There are no contracts to load.");
          }

          if (error === null) {
            this.principalListener.emit(BlockChainEvent.END_CONTRACT_LOADING);
          }

          if (callback) callback(error, this);
        } else {
          UtilsHelpers.debugger("Invalid provider");
          if (callback) callback(AppErrorCode.INVALID_PROVIDER, this);
        }
      } else {
        UtilsHelpers.debugger("Web3 can not be loaded.");
        if (callback) callback(AppErrorCode.INVALID_PROVIDER);
      }
    } else {
      UtilsHelpers.debugger("Contracts state is not valid..");
      if (callback) callback(AppErrorCode.INVALID_CONTRACT_LOADING);
    }

    return this;
  }

  private async _loadContract(
    contractData: ContractData
  ): Promise<AppErrorCode | null> {
    let error: AppErrorCode | null = AppErrorCode.INVALID_CONTRACT_LOADING;

    if (this._contractDataIsValid(contractData)) {
      let network = await this._networkIsValidInContract(contractData);

      if (network && this._web3) {
        this._contracts[contractData.contract] =
          (await new this._web3.eth.Contract(
            contractData.data?.abi,
            network.address
          )) as any;

        UtilsHelpers.debugger(
          "Load contract (" +
            contractData.contract +
            " - " +
            network.address +
            ")"
        );

        if (!!this._contracts[contractData.contract]) {
          this._loadContractInstance(
            this._contracts[contractData.contract],
            contractData.contract
          );
          this.principalListener.emit(
            BlockChainEvent.LOAD_CONTRACT,
            contractData.contract
          );
          error = null;
        }
      } else {
        UtilsHelpers.debugger("You are in incorrect network.");
        this.principalListener.emit(
          AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
          null
        );
        error = AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK;
      }
    }

    return error;
  }

  private _loadContractInstance(relatedContract: any, contractName: Contract) {
    if (this._web3 && this.selectedAccount) {
      switch (contractName) {
        case Contract.CONNECTIONS:
          this._contractsInstance[contractName] = new Connections(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.FACTORIES:
          this._contractsInstance[contractName] = new Factories(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.TOKEN:
          this._contractsInstance[contractName] = new Token(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.EMPLOYEES:
          this._contractsInstance[contractName] = new Employees(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        default:
          break;
      }
    }
  }

  get factories() {
    return this._contractsInstance[Contract.FACTORIES];
  }

  get connections() {
    return this._contractsInstance[Contract.CONNECTIONS];
  }

  get token() {
    return this._contractsInstance[Contract.TOKEN];
  }

  get employees() {
    return this._contractsInstance[Contract.EMPLOYEES];
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Validations                             */
  /* -------------------------------------------------------------------------- */

  private async _networkIsValidInContract(contract: ContractData) {
    let network: ABINetworkData | undefined | boolean = false;

    if (this._web3) {
      let networkId = await this._web3.eth.net.getId();

      UtilsHelpers.debugger("Load contract from " + networkId);

      if (networkId === parseInt(BlockChainHelpers.getAppChain().chainId, 16)) {
        network = contract?.data?.networks[networkId];
      }
    }

    return !!network ? network : false;
  }

  private _contractDataIsValid(contract: ContractData | null | undefined) {
    return (
      contract !== undefined &&
      contract !== null &&
      !!contract.data &&
      !!contract.contract &&
      !!contract.data.abi
    );
  }

  private _contractsStateIsValid(contractsState?: ContractsState) {
    let state: ContractsState | null = contractsState
      ? contractsState
      : this._contractsData;

    return state /* && state?.Connections */;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Getters                                 */
  /* -------------------------------------------------------------------------- */

  get selectedAccount() {
    return this._accounts?.length ? this._accounts[0] : null;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                            ANCHOR Class actions                            */
  /* -------------------------------------------------------------------------- */

  private _destroy() {
    this.principalListener.removeAllListeners();
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  static saveBlockChainController(
    state: BlockChainState,
    controller: BlockChain
  ): BlockChainState {
    return {
      ...state,
      controller,
      customer: null,
      error: null,
      firstLoad: true,
    };
  }

  static destroyBlockChainController(state: BlockChainState): BlockChainState {
    if (state.controller) state.controller._destroy();
    return { ...state, controller: null, error: null };
  }

  static setBlockChainError(
    state: BlockChainState,
    error: AppErrorCode
  ): BlockChainState {
    if (ULTRA_ERRORS.includes(error)) {
      return { ...state, error, customer: null, controller: null };
    } else return { ...state, error };
  }

  /* -------------------------------------------------------------------------- */
}
