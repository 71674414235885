export enum AppMode {
  DEV = "dev",
  TEST = "test",
  PRO = "pro",
}

export enum AppChain {
  BSC = "bsc",
  GANACHE = "ganache",
  BSCT = "bsc-test",
}

export enum AppErrorCode {
  BAD_REQUEST = "bad_request",
  INCORRECT_BLOCKCHAIN_NETWORK = "incorrect-block-chain-network",
  INVALID_PROVIDER = "invalid-provider",
  INVALID_CONTRACT_LOADING = "invalid-contract-loading",
  // NFT errors
  MINT_IS_NOT_VALID = "mint-is-not-valid",
  RESET_DEPLOYMENTS_ERROR = "reset-deployments-error",
  MINT_ERROR = "mint-error",
  BURN_ERROR = "burn-error",
  UPGRADE_ERROR = "upgrade-error",
  GROW_ERROR = "grow-error",
  GROW_ALL_ERROR = "grow-all-error",
  CANT_GROW_ERROR = "cant-grow-error",
  CANT_GROW_ALL_ERROR = "cant-grow-all-error",
  HAVENT_UPGRADES = "havent-upgrades",
  ADD_TO_WHITELIST = "add-to-whitelist",
  // App errors
  USER_DENIED_TRANSACTION = "user-denied-transaction",
  INVALID_APPROVED_SPEND = "invalid-approved-spend",
  INVALID_TESTNET_REQUEST = "invalid-testnet-request",
  //Connections errors
  ADD_MODEL_ERROR = "add-model-error",
  ADD_TYPE_ERROR = "add-type-error",
  REMOVE_MODEL_ERROR = "remove-model-error",
  REMOVE_TYPE_ERROR = "remove-type-error",
  PLAY_WITH_FACTORY_ERROR = "play-with-factory-error",
}

export const ULTRA_ERRORS = [
  AppErrorCode.INVALID_PROVIDER,
  AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
  AppErrorCode.INVALID_CONTRACT_LOADING,
];

export const appConfig = {
  mode: AppMode.TEST,
};

export interface AppError {
  message: string;
  code: AppErrorCode;
}

export interface Currency {
  name?: string;
  symbol?: string;
  decimals?: number;
}

export interface ChainData {
  chainId: string;
  chainName?: string;
  nativeCurrency?: Currency;
  rpcUrls?: string[];
  blockExplorerUrls?: string[] | null;
}

export type PosibleChainsType = {
  [mode in AppChain]: ChainData;
};

export const artHost = {
  [AppMode.DEV]: "https://art.businessbuilders.city/static",
  [AppMode.TEST]: "https://art.businessbuilders.city/static",
  [AppMode.PRO]: "https://art.businessbuilders.city/static",
};

export const navigationLinks = [
  {
    display: "Building",
    links: [
      {
        href: "/builder",
        display: "NFTs",
        icon: "city",
        type: "internal",
      },
    ],
  },
  {
    display: "NFT Staking",
    links: [
      {
        href: "/production",
        display: "Factories",
        icon: "city",
        type: "internal",
      },
      {
        href: "/employees",
        display: "Employees",
        icon: "city",
        type: "internal",
      },
    ],
  },
  {
    display: "NFT Game",
    links: [
      {
        href: "/relations-game",
        display: "Relations",
        icon: "city",
        type: "internal",
      },
    ],
  },
  {
    display: "Information",
    links: [
      {
        href: "https://docs.businessbuilders.city/",
        display: "Documentation",
        icon: "docs",
        type: "external",
      },
      {
        href: "https://instagram.com/businessbuildersnft",
        display: "Instagram",
        icon: "docs",
        type: "external",
      },
      {
        href: "https://twitter.com/NFTBBuilders",
        display: "Twitter",
        icon: "docs",
        type: "external",
      },
    ],
  },
];

export const posibleChains: PosibleChainsType = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: "BSC Testnet",
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [AppChain.GANACHE]: {
    chainId: "0x539",
    chainName: "GANACHE",
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["http://localhost:7545"],
    blockExplorerUrls: null,
  },
};
