import { useState } from "react";
import { UtilsHelpers } from "../../../core/helpers/utils";
import { FactoryNFT } from "../../../core/nft";
import "./city.styles.css";
import "./nft.styles.css";

export interface StaticNFTProps {
  factoryNft: FactoryNFT;
  staticImage?: boolean;
  employeeNftPrice?: number;
  customerBalance?: number;
  className?: string;
  small?: boolean;
  onGrow?: () => void;
  onTransfer?: (to: string) => void;
  onBurn?: () => void;
  onDeployEmployee?: () => void;
  onResetDeployments?: () => void;
  onNFTClick?: () => void;
}

enum NFTMessages {
  BURN = "burn",
  TRANSFER = "transfer",
  DEPLOY = "deploy",
  GROW = "grow",
  RESET_DEPLOYMENTS = "reset-deployments",
}

export const StaticCityNFT = (props: StaticNFTProps) => {
  const [actionMessage, changeMessage] = useState<NFTMessages | null>(null);
  const [clickedState, changeClickedState] = useState<boolean>(false);

  let selectedMessage = null;

  switch (actionMessage) {
    case NFTMessages.BURN:
      selectedMessage = (
        <div className="ct-message">
          <p>
            Burn your factory. You will receive ({" "}
            {UtilsHelpers.parseMoney(props.factoryNft.burnReward)} FCT ). The
            NFT will be transfer to burn address.
          </p>
          <button
            className="ct-main-button"
            onClick={() => {
              if (props.onBurn) props.onBurn();
              changeMessage(null);
            }}
          >
            Confirm
          </button>
        </div>
      );
      break;
    case NFTMessages.GROW:
      selectedMessage = (
        <div className="ct-message">
          <p>Grow your factory</p>
        </div>
      );
      break;
    case NFTMessages.DEPLOY:
      selectedMessage = (
        <div className="ct-message">
          <p>
            Deploy an employee from your factory with <br /> ({" "}
            {props.employeeNftPrice} FCT ). Your Factory has{" "}
            {props.factoryNft.appDataModel.pageInfo.maxDeployPerFactory -
              props.factoryNft.deployedCount}{" "}
            deployments.
          </p>
          <button
            className="ct-main-button"
            onClick={() => {
              if (props.onDeployEmployee) props.onDeployEmployee();
              changeMessage(null);
            }}
          >
            Confirm
          </button>
        </div>
      );
      break;
    case NFTMessages.RESET_DEPLOYMENTS:
      selectedMessage = (
        <div className="ct-message">
          <p>
            You have{" "}
            {props.factoryNft.deployedCount +
              "/" +
              props.factoryNft.appDataModel.pageInfo.maxDeployPerFactory}
            . If you want to deploy more employees with this factory. You need
            to spend ({" "}
            {UtilsHelpers.parseMoney(
              props.factoryNft.appDataModel.pricesInfo.resetDeploysPrice || 0
            )}{" "}
            FCT )
          </p>
          <button
            className="ct-main-button"
            disabled={
              props.factoryNft.appDataModel.pricesInfo.resetDeploysPrice &&
              props.customerBalance
                ? props.factoryNft.appDataModel.pricesInfo.resetDeploysPrice >
                  props.customerBalance
                : true
            }
            onClick={() => {
              if (props.onResetDeployments) props.onResetDeployments();
              changeMessage(null);
            }}
          >
            Confirm
          </button>
        </div>
      );
      break;
    default:
      selectedMessage = null;
      break;
  }

  return (
    <div
      className={"ct-nft " + props.className + (props.small ? " ct-small" : "")}
      onClick={
        props.onNFTClick
          ? () => {
              if (props.onNFTClick) {
                props.onNFTClick();
                changeClickedState(!clickedState);
              }
            }
          : () => {}
      }
    >
      <div className="ct-image">
        <img
          src={
            props.staticImage
              ? props.factoryNft.uri
              : props.factoryNft.uri + "&onlyImage=true"
          }
          alt=""
        />
      </div>
      <div className="ct-data">
        <span>
          <strong>ID: #</strong> {props.factoryNft.id}
        </span>
        <span>
          <strong>Type: </strong>{" "}
          {UtilsHelpers.getTypeName(props.factoryNft.type)}
        </span>
        <span>
          <strong>Model: </strong> {props.factoryNft.model}
        </span>
        {props.factoryNft.multiplier ? (
          <span>
            <strong>Multiplier: </strong> {props.factoryNft.multiplier}
          </span>
        ) : (
          ""
        )}
        {typeof props.factoryNft.deployedCount === "number" && !props.small ? (
          <span>
            <strong>Deployed employees: </strong>{" "}
            {props.factoryNft.deployedCount} /{" "}
            {props.factoryNft.appDataModel.pageInfo.maxDeployPerFactory}
          </span>
        ) : (
          ""
        )}
        {props.factoryNft.growTimestamp &&
        props.factoryNft.growTimeDiference &&
        !props.small ? (
          <span>
            <strong>Grow time: </strong>{" "}
            {props.factoryNft.growTimeDiferenceInHours.toFixed(2)} Hours
          </span>
        ) : (
          ""
        )}
        {props.factoryNft.playRewards > 0 && !props.small ? (
          <span>
            <strong>Game rewards: </strong> {props.factoryNft.playRewards}
          </span>
        ) : (
          ""
        )}
        {!props.factoryNft.canPlay ? (
          <span>
            <strong>Play in: </strong> {props.factoryNft.playInTime.toFixed(0)}{" "}
            Hours
          </span>
        ) : (
          ""
        )}
      </div>
      {!props.small ? (
        <div className="ct-actions-container">
          <div className="ct-actions">
            {props.onGrow && props.factoryNft.growReward ? (
              <div className="ct-reward-data">
                <button
                  className="ct-main-button"
                  disabled={!props.factoryNft.canGrow()}
                  onClick={() => (props.onGrow ? props.onGrow() : null)}
                >
                  Grow ({UtilsHelpers.parseMoney(props.factoryNft.growReward)}{" "}
                  FCT)
                </button>
                <div className="ct-rewards-info">
                  <small>
                    Bonus per factories (
                    {props.factoryNft.bonusPerFactories.toFixed(2)})
                  </small>
                  <small>
                    Bonus per time ({props.factoryNft.bonusPerTime.toFixed(2)})
                  </small>
                  <small>
                    Normal reward ({props.factoryNft.normalReward.toFixed(2)})
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.onTransfer ? (
              <button
                className="ct-main-button"
                onClick={() => (props.onTransfer ? props.onTransfer("") : null)}
              >
                <span className="fas fa-send"></span>
              </button>
            ) : (
              " "
            )}
            {props.onBurn ? (
              <button
                className={
                  "ct-main-button" +
                  (actionMessage === NFTMessages.BURN ? " ct-active" : "")
                }
                onClick={() =>
                  actionMessage === NFTMessages.BURN
                    ? changeMessage(null)
                    : changeMessage(NFTMessages.BURN)
                }
              >
                {actionMessage === NFTMessages.BURN ? (
                  <span className="fas fa-times"></span>
                ) : (
                  <span className="fas fa-trash"></span>
                )}
              </button>
            ) : (
              " "
            )}
            {props.onDeployEmployee ? (
              <button
                disabled={!props.factoryNft.canDeployMoreEmployee}
                className={
                  "ct-main-button" +
                  (actionMessage === NFTMessages.DEPLOY ? " ct-active" : "")
                }
                onClick={() =>
                  actionMessage === NFTMessages.DEPLOY
                    ? changeMessage(null)
                    : changeMessage(NFTMessages.DEPLOY)
                }
              >
                {actionMessage === NFTMessages.DEPLOY ? (
                  <span className="fas fa-times"></span>
                ) : (
                  <span className="fas fa-people-carry"></span>
                )}
              </button>
            ) : (
              " "
            )}
            {!props.factoryNft.canDeployMoreEmployee &&
            props.onResetDeployments ? (
              <button
                className="ct-main-button"
                disabled={
                  props.factoryNft.appDataModel.pricesInfo.resetDeploysPrice &&
                  props.customerBalance
                    ? props.factoryNft.appDataModel.pricesInfo
                        .resetDeploysPrice > props.customerBalance
                    : true
                }
                onClick={() => changeMessage(NFTMessages.RESET_DEPLOYMENTS)}
              >
                <span className="fas fa-trash-restore"></span>
              </button>
            ) : (
              " "
            )}
          </div>
          <div className="ct-action-message">{selectedMessage}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
