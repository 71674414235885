import { UtilsHelpers } from "../../../core/helpers/utils";
import BBLogo from "../../../assets/images/BB_LOGO.svg";
import { RouteComponentProps, withRouter } from "react-router";

import { useEffect } from "react";

import "./top.styles.css";

interface TopNavigationProps {
  onToggleLeftNav: () => void;
  onLoadBlockChain: () => void;
  onLoadCustomerData: () => void;
  onToggleLoader: (force: boolean, pageLoad: boolean) => void;
  restorePageLoading: boolean;
  walletAddress: string | null | undefined;
  tokenBalance: number | null | undefined;
  tokenAddress: string | null | undefined;
}

const UnlinkTopNavigation = (
  props: TopNavigationProps & RouteComponentProps
) => {
  useEffect(() => {
    const routerListener = props.history.listen(() => {
      window.scrollTo(0, 0);
      props.onToggleLoader(true, true);
    });

    return () => {
      if (routerListener) routerListener();
    };
  }, [props, props.history]);

  if (props.restorePageLoading) {
    setTimeout(() => {
      props.onToggleLoader(false, false);
    }, 2000);
  }

  return (
    <div className="ct-top-nav">
      <div className="ct-max-container">
        <div className="ct-title">
          <h1>
            <img src={BBLogo} alt="BusinessBuilders logo" />
          </h1>
        </div>

        <div className="ct-links">
          {props.walletAddress ? (
            <div className="ct-data">
              <p className="ct-balance">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=" +
                    props.tokenAddress
                  }
                >
                  <span className="fas fa-plus"></span>
                </a>
                {props.tokenBalance
                  ? UtilsHelpers.parseMoney(props.tokenBalance)
                  : 0}{" "}
                FCT
              </p>{" "}
              <p className="ct-address">{props.walletAddress}</p>
            </div>
          ) : (
            <button
              onClick={() =>
                props.onLoadBlockChain ? props.onLoadBlockChain() : null
              }
            >
              Connect
            </button>
          )}
          <div className="ct-actions">
            <span
              onClick={() => props.onLoadCustomerData()}
              className="fas fa-sync-alt"
            ></span>
            <span
              onClick={() => props.onToggleLeftNav()}
              className="ct-navbar-toggler fas fa-bars"
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TopNavigation = withRouter(UnlinkTopNavigation);
