import { ContractBaseModel } from "../abstracts/contract";
import { AbstractsEmployees } from "../abstracts/employees";
import { AppErrorCode } from "../app";

export class Employees extends ContractBaseModel<AbstractsEmployees> {
  uri() {
    return this.contract.uri().call(this.config);
  }

  getEmployee(id: number) {
    return this.contract.getEmployee(id).call(this.config);
  }

  tokenURI(id: number) {
    return this.contract.tokenURI(id).call(this.config);
  }

  mint(
    to: string,
    buildType: string,
    buildModel: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mint(to, buildType, buildModel)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  burn(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burn(id)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.BURN_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.BURN_ERROR, error, callback)
      );
  }

  getMultiplier(id: number) {
    return this.contract.getMultiplier(id).call(this.config);
  }

  getParts(id: number) {
    return this.contract.getParts(id).call(this.config);
  }

  getCustomerEmployees(owner: string) {
    return this.contract.getCustomerEmployees(owner).call(this.config);
  }
}
