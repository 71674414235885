import { AnyAction } from "redux";
import {
  Contract,
  ContractData,
  ContractsStateController,
  // ContractsStateController,
} from "../../../core/contracts";
import { AppActions, AppReducer } from "../../types";

export const contractsKey = AppReducer.CONTRACTS;

export interface ContractsState {
  [Contract.CONNECTIONS]?: ContractData | null;
  [Contract.TOKEN]?: ContractData | null;
  [Contract.FACTORIES]?: ContractData | null;
  [Contract.EMPLOYEES]?: ContractData | null;
}

export const initialState: ContractsState = {
  [Contract.CONNECTIONS]: null,
  [Contract.TOKEN]: null,
  [Contract.FACTORIES]: null,
  [Contract.EMPLOYEES]: null,
};

export const contractsReducer = (
  state: ContractsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.SET_MULTIPLE_CONTRACT_ABI:
      return ContractsStateController.setMultipleContractsAbi(
        state,
        action.contracts
      );
    default:
      return state;
  }
};
