import { AnyAction } from "redux";
import { AppActions, AppReducer } from "../../types";

export const applicationKey = AppReducer.APP;

export interface ApplicationState {
  loader: boolean;
  restorePageLoad: boolean;
  leftNavigation: boolean;
  factoriesPagination: number;
  employeesPagination: number;
}

export const initialState: ApplicationState = {
  loader: false,
  restorePageLoad: false,
  leftNavigation: false,
  factoriesPagination: 0,
  employeesPagination: 0,
};

export const applicationReducer = (
  state: ApplicationState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.CHANGE_FACTORIES_PAGINATION:
      return {
        ...state,
        factoriesPagination: action.page,
      };
    case AppActions.CHANGE_EMPLOYEES_PAGINATION:
      return {
        ...state,
        employeesPagination: action.page,
      };
    case AppActions.TOGGLE_LOADER:
      return {
        ...state,
        loader: action.force !== undefined ? action.force : !state.loader,
        restorePageLoad: !!action.pageLoad,
      };
    case AppActions.TOGGLE_LEFT_NAVIGATION:
      return {
        ...state,
        leftNavigation:
          action.force !== undefined ? action.force : !state.leftNavigation,
      };
    default:
      return state;
  }
};
