import { AppErrorCode } from "../../../core/app";
import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./main.styles.css";

export interface ErrorComponentProps {
  code: AppErrorCode;
  onApproveTokenSpend?: () => void;
  onChangeNetwork?: () => void;
  onRemoveError: () => void;
}

export const ErrorComponent = (props: ErrorComponentProps) => {
  let error: any = "";

  switch (props.code) {
    case AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK:
      error = (
        <div className="ct-error ct-blockchain-error">
          <h4>
            You are in incorrect network, change to{" "}
            {BlockChainHelpers.getAppChain().chainName}. ERROR CODE.{" "}
          </h4>
          <button
            className="ct-main-button"
            onClick={() =>
              props.onChangeNetwork ? props.onChangeNetwork() : null
            }
          >
            Change Network
          </button>
        </div>
      );
      break;
    case AppErrorCode.INVALID_APPROVED_SPEND:
      error = (
        <div className="ct-error ct-approved-spend-error">
          <h4>You should approve token spend </h4>
          <button
            className="ct-main-button"
            onClick={() =>
              props.onApproveTokenSpend ? props.onApproveTokenSpend() : null
            }
          >
            Approve
          </button>
        </div>
      );
      break;
    case AppErrorCode.INVALID_CONTRACT_LOADING:
      error = (
        <div className="ct-error ct-approved-spend-error">
          <h4>There was an error while loading the data. Please wait.</h4>
          <button
            onClick={() => props.onRemoveError()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
    case AppErrorCode.USER_DENIED_TRANSACTION:
      error = (
        <div className="ct-error ct-approved-spend-error">
          <h4>The user denied the transaction.</h4>
          <button
            onClick={() => props.onRemoveError()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
    default:
      error = (
        <div className="ct-error ct-approved-spend-error">
          <h4>Undefined error</h4>
          <button
            onClick={() => props.onRemoveError()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
  }

  return <div className="ct-error-container">{error}</div>;
};
