import { PlayData } from "../components/organisms/game/relations";
import { AppErrorCode } from "./app";
import { BlockChain } from "./chain";
import { UtilsHelpers } from "./helpers/utils";
import { AppData } from "./types";

export enum StaticNFTType {
  NUCLEAR = 1,
  WATER = 2,
  FOOD = 3,
  COAL = 4,
  WOOD = 5,
}

export enum StaticNFTResources {
  WATER = 1,
  ENERGY = 2,
  WOOD = 3,
  FOOD = 4,
}

export interface NFTTemplate {
  id: number;
  model?: number;
  type?: number;
  multiplier?: number;
  uri?: string;
  deployedCount?: number;
  factoryPlayTime?: number;
  playRewards?: number;
  growTimestamp?: number;
  canFactoryPlay?: boolean;
  canFactoryGrow?: boolean;
}

export class FactoryNFT {
  public id?: number;
  public type?: number;
  public model?: number;
  public multiplier?: number;
  public growTimestamp: number;
  public nextUpgradePrice: number;
  public uri: string;
  public initialized: boolean;

  constructor(private _template: NFTTemplate, public appDataModel: AppData) {
    this.id = Number(this._template.id);
    this.type = Number(this._template.type);
    this.model = Number(this._template.model);
    this.uri = this._template.uri || "";
    this.multiplier = Number(this._template.multiplier);
    this.nextUpgradePrice = 0;
    this.initialized = false;

    let growTime = 0;

    if (!isNaN(Number(this._template.growTimestamp))) {
      growTime = Number(this._template.growTimestamp) * 1000;
    }

    this.growTimestamp = growTime;

    this.init();
  }

  static calcGrowReward(
    growFactoryReward: number,
    multiplier: number,
    growTimeDiference: number,
    numberOfFactories: number,
    playRewards: number
  ) {
    return (
      FactoryNFT.calcNormalReward(
        growFactoryReward,
        multiplier,
        growTimeDiference
      ) +
      FactoryNFT.calcBonusPerFactories(numberOfFactories) +
      FactoryNFT.calcBonusPerTime(growFactoryReward, growTimeDiference) +
      playRewards
    );
  }

  static calcBonusPerFactories(numberOfFactories: number) {
    return numberOfFactories;
  }

  static calcNormalReward(
    growFactoryReward: number,
    multiplier: number,
    growTimeDiference: number
  ) {
    return growFactoryReward * multiplier * growTimeDiference;
  }

  static calcBonusPerTime(
    growFactoryReward: number,
    growTimeDiference: number
  ) {
    return (Math.pow(growTimeDiference, 2) * growFactoryReward) / 10000;
  }

  async init() {
    this.initialized = true;
  }

  get canPlay() {
    return this._template.canFactoryPlay;
  }

  get playInTime() {
    return (
      ((Date.now() -
        (this._template.factoryPlayTime || 0) * 1000 -
        this.appDataModel.timesInfo.minPlayTime * 1000) /
        1000 /
        60 /
        60) *
      -1
    );
  }

  get growTimeDiference() {
    return (Date.now() - this.growTimestamp) / 1000;
  }

  get growTimeDiferenceInHours() {
    return (Date.now() - this.growTimestamp) / 1000 / 60 / 60;
  }

  get growTimeDiferenceInSeconds() {
    return (Date.now() - this.growTimestamp) / 1000;
  }

  get canDeployMoreEmployee() {
    return this._template.deployedCount &&
      !isNaN(Number(this._template.deployedCount))
      ? this._template.deployedCount <
          this.appDataModel.pageInfo.maxDeployPerFactory
      : false;
  }

  get burnReward() {
    return (
      ((this.appDataModel.pricesInfo.factoryPrice || 0) *
        (this.appDataModel.pageInfo.burnReward || 0)) /
      100
    );
  }

  get deployedCount() {
    return Number(this._template.deployedCount || 0);
  }

  get playRewards() {
    return Number(this._template.playRewards || 0);
  }

  get growReward() {
    return FactoryNFT.calcGrowReward(
      this.appDataModel.pageInfo.factoryGrowReward,
      this.multiplier || 0,
      this.growTimeDiferenceInSeconds,
      this.appDataModel.customerInfo.factories.length,
      Number(this._template.playRewards || 0)
    );
  }

  get bonusPerTime() {
    return FactoryNFT.calcBonusPerTime(
      this.appDataModel.pageInfo.factoryGrowReward,
      this.growTimeDiferenceInSeconds
    );
  }

  get bonusPerFactories() {
    return FactoryNFT.calcBonusPerFactories(
      this.appDataModel.customerInfo.factories.length
    );
  }

  get normalReward() {
    return FactoryNFT.calcNormalReward(
      this.appDataModel.pageInfo.factoryGrowReward,
      this.multiplier || 0,
      this.growTimeDiferenceInSeconds
    );
  }

  canGrow() {
    return (
      this.growTimeDiferenceInSeconds > this.appDataModel.timesInfo.minGrowTime
    );
  }

  static createWithContractTemplate(
    contractTemplate: { [id: string]: any },
    appData: AppData
  ) {
    const factory = contractTemplate[0][0];

    return new FactoryNFT(
      {
        id: contractTemplate.id,
        type: factory[0],
        model: factory[1],
        multiplier: factory[2],
        uri: contractTemplate[0][1],
        deployedCount: contractTemplate[3],
        factoryPlayTime: contractTemplate[4],
        playRewards: contractTemplate[5],
        growTimestamp: contractTemplate[6],
        canFactoryPlay: contractTemplate[1],
        canFactoryGrow: contractTemplate[2],
      },
      appData
    );
  }

  public static mintNFT(
    blockChain: BlockChain,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint NFT");

    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + blockChain.selectedAccount + ")"
      );
      if (callback) blockChain.connections.mintFactory(callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public static growNFT(
    blockChain: BlockChain,
    nftId: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Grow NFT (" + blockChain.selectedAccount + ")"
      );

      if (callback) {
        blockChain.connections.growFactory(nftId, callback);
      }
    } else {
      UtilsHelpers.debugger("Grow is not available.");
      if (callback) callback(AppErrorCode.GROW_ERROR);
    }
  }

  public static growAllNFT(
    blockChain: BlockChain,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Grow NFT (" + blockChain.selectedAccount + ")"
      );

      if (callback) blockChain.connections.growAllFactories(callback);
    } else {
      UtilsHelpers.debugger("Grow is not available.");
      if (callback) callback(AppErrorCode.GROW_ALL_ERROR);
    }
  }

  public static burnNFT(
    id: number,
    blockChain: BlockChain,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Burn NFT (" + blockChain.selectedAccount + ")"
      );

      if (callback) blockChain.connections.burnFactory(id, callback);
    } else {
      UtilsHelpers.debugger("Burn is not available.");
      if (callback) callback(AppErrorCode.BURN_ERROR);
    }
  }

  public static restoreDeploymentsNFT(
    id: number,
    blockChain: BlockChain,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Burn NFT (" + blockChain.selectedAccount + ")"
      );

      if (callback) blockChain.connections.resetMaxFactoryDeploy(id, callback);
    } else {
      UtilsHelpers.debugger("Burn is not available.");
      if (callback) callback(AppErrorCode.BURN_ERROR);
    }
  }

  public static playWithFactory(
    id: number,
    employees: number[],
    blockChain: BlockChain,
    callback: (error: AppErrorCode | null, playData: PlayData | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try to play with NFT (" + blockChain.selectedAccount + ")"
      );

      if (callback) {
        blockChain.connections.playWithFactory(id, employees, callback);
      }
    } else {
      UtilsHelpers.debugger("Burn is not available.");
      if (callback) callback(AppErrorCode.PLAY_WITH_FACTORY_ERROR, null);
    }
  }
}
