import { UtilsHelpers } from "./helpers/utils";

export interface PageInfo {
  buildTypes: string[];
  buildModels: string[];
  maxDeployPerFactory: number;
  maxFactories: number;
  burnReward: number;
  factoryGrowReward: number;
  playRelationsBase: number;
}

export interface PricesInfo {
  factoryPrice: number;
  playRelationsBase: number;
  resetDeploysPrice: number;
  employeePrice: number;
}

export interface TimesInfo {
  minPlayTime: number;
  minGrowTime: number;
  maxGrowTime: number;
}

export interface CustomerInfo {
  factories: string[];
  employees: string[];
  canMakeTestnetRequest: boolean;
  tokenBalance: number;
}

export class AppData {
  constructor(
    public pageInfo: PageInfo,
    public pricesInfo: PricesInfo,
    public timesInfo: TimesInfo,
    public customerInfo: CustomerInfo
  ) {}

  static buildFromTemplates(
    pageInfo: any,
    pricesInfo: any,
    timesInfo: any,
    customerInfo: any,
    canMakeTestnetRequest: boolean,
    tokenBalance: string
  ) {
    return new AppData(
      {
        buildTypes: pageInfo[3],
        buildModels: pageInfo[4],
        maxDeployPerFactory: Number(pageInfo[0]),
        maxFactories: Number(pageInfo[1]),
        burnReward: Number(pageInfo[2]),
        factoryGrowReward: UtilsHelpers.normalizeWei(pageInfo[5]),
        playRelationsBase: pageInfo[6],
      },
      {
        factoryPrice: UtilsHelpers.normalizeWei(pricesInfo[0]),
        playRelationsBase: UtilsHelpers.normalizeWei(pricesInfo[2]),
        resetDeploysPrice: UtilsHelpers.normalizeWei(pricesInfo[3]),
        employeePrice: UtilsHelpers.normalizeWei(pricesInfo[1]),
      },
      {
        minPlayTime: Number(timesInfo[0]),
        minGrowTime: Number(timesInfo[1]),
        maxGrowTime: Number(timesInfo[2]),
      },
      {
        factories: customerInfo[0],
        employees: customerInfo[1],
        canMakeTestnetRequest,
        tokenBalance: UtilsHelpers.normalizeWei(tokenBalance),
      }
    );
  }
}
