import React from "react";
import { AppErrorCode } from "../../core/app";
import { EmployeeNFT } from "../../core/employee";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { FactoryNFT } from "../../core/nft";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ContractsState } from "../../storage/state/contracts/state";
import { EmployeeNFTModal, FactoryNFTModal } from "../molecules/modal/nft";
import { BuilderActions } from "../organisms/builder/actions";
import { BuilderFilters, Filter } from "../organisms/builder/filters";
import {
  BuilderEmployeesGrid,
  BuilderFactoryGrid,
} from "../organisms/builder/grid";

interface BuilderComponentProps {
  onToggleLoader: (froce: boolean) => void;
  onLoadCustomerData: () => void;
  onLoadBlockChain: () => void;
  onChangeFactoriesPagination: (page: number) => void;
  onChangeEmployeesPagination: (page: number) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
  active: boolean;
  factoriesPagination: number;
  employeesPagination: number;
  blockChain: BlockChainState;
  contracts: ContractsState;
}

interface BuilderComponentState {
  filter: Filter;
  openFilters: boolean;
  openFactoryNFTModal: boolean;
  openEmployeeNFTModal: boolean;
  factoryNewNFTs: FactoryNFT[];
  employeeNewNFTs: EmployeeNFT[];
}

export class BuilderPage extends React.Component<
  BuilderComponentProps,
  BuilderComponentState
> {
  constructor(props: BuilderComponentProps) {
    super(props);

    this.state = {
      filter: {
        type: null,
      },
      openFilters: false,
      openFactoryNFTModal: false,
      openEmployeeNFTModal: false,
      factoryNewNFTs: [],
      employeeNewNFTs: [],
    };
  }

  componentDidUpdate(prevProps: BuilderComponentProps) {
    if (prevProps.blockChain.customer && this.props.blockChain.customer) {
      let prevFactoryNFTs =
        prevProps.blockChain.customer?.factoryNFTArray || [];
      let prevEmployeeNFTs =
        prevProps.blockChain.customer?.employeeNFTArray || [];
      let nextFactoryNFTs =
        this.props.blockChain.customer?.factoryNFTArray || [];
      let nextEmployeeNFTs =
        this.props.blockChain.customer?.employeeNFTArray || [];

      if (
        prevFactoryNFTs.length < nextFactoryNFTs.length ||
        prevEmployeeNFTs.length < nextEmployeeNFTs.length
      ) {
        let newFactoryNFTs: FactoryNFT[] = [];
        let newEmployeeNFTs: EmployeeNFT[] = [];

        for (let i = 0; i < nextFactoryNFTs.length; i++) {
          let inPrevArray = false;

          for (let j = 0; j < prevFactoryNFTs.length; j++) {
            let prevNFT = prevFactoryNFTs[j];
            let nextNFT = nextFactoryNFTs[i];

            if (prevNFT.id === nextNFT.id) {
              inPrevArray = true;
              break;
            }
          }

          if (!inPrevArray) {
            newFactoryNFTs.push(nextFactoryNFTs[i]);
          }
        }

        for (let i = 0; i < nextEmployeeNFTs.length; i++) {
          let inPrevArray = false;

          for (let j = 0; j < prevEmployeeNFTs.length; j++) {
            let prevNFT = prevEmployeeNFTs[j];
            let nextNFT = nextEmployeeNFTs[i];

            if (prevNFT.id === nextNFT.id) {
              inPrevArray = true;
              break;
            }
          }

          if (!inPrevArray) {
            newEmployeeNFTs.push(nextEmployeeNFTs[i]);
          }
        }

        this.setState({
          factoryNewNFTs: newFactoryNFTs,
          employeeNewNFTs: newEmployeeNFTs,
          openFactoryNFTModal: !!newFactoryNFTs.length,
          openEmployeeNFTModal:
            !!newEmployeeNFTs.length && !newFactoryNFTs.length,
        });
      }
    }
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (!error) this.props.onLoadCustomerData();
    else this.props.onSetBlockChainError(error);
  }

  resetModals() {
    this.setState({
      factoryNewNFTs: [],
      employeeNewNFTs: [],
      openFactoryNFTModal: false,
      openEmployeeNFTModal: false,
    });
  }

  render() {
    const filteredNFTs = [];
    const validatedNFTs =
      this.props.blockChain?.customer?.factoryNFTArray || [];

    for (let i = 0; i < validatedNFTs.length || 0; i++) {
      if (this.state.filter.type !== null) {
        if (this.state.filter.type === validatedNFTs[i].type) {
          filteredNFTs.push(validatedNFTs[i]);
        }
      } else filteredNFTs.push(validatedNFTs[i]);
    }

    return (
      <React.Fragment>
        <div className="ct-max-container ct-builder-page">
          <div
            className={
              "ct-shadow" + (this.props.active ? "ct-active-page" : "")
            }
          >
            {!this.props.active ? <h1>Comming Soon</h1> : ""}
          </div>
          <FactoryNFTModal
            nft={this.state.factoryNewNFTs}
            open={this.state.openFactoryNFTModal}
            onClose={() => this.resetModals()}
          />
          <EmployeeNFTModal
            nft={this.state.employeeNewNFTs}
            open={this.state.openEmployeeNFTModal}
            onClose={() => this.resetModals()}
          />
          <div className="ct-factories">
            <h1 className="ct-text-center ct-text-light ct-pt-30">Factories</h1>
            <BuilderActions
              onToggleFilter={() =>
                this.setState({ openFilters: !this.state.openFilters })
              }
              onGetINV={async () => {
                let provider = BlockChainHelpers.getProvider();
                if (
                  this.props.blockChain.customer?.canMakeTestnetRequest &&
                  this.props.blockChain.controller?.selectedAccount &&
                  provider
                ) {
                  this.props.onToggleLoader(true);
                  this.props.blockChain.controller.token?.requestTestnetTokens(
                    (error: AppErrorCode | null) => this.onUpdateData(error)
                  );
                }
              }}
              onMint={() => {
                if (this.props.blockChain?.controller) {
                  this.resetModals();
                  this.props.onToggleLoader(true);
                  FactoryNFT.mintNFT(
                    this.props.blockChain.controller,
                    (error: AppErrorCode | null) => this.onUpdateData(error)
                  );
                }
              }}
              canBuildNewNFT={
                this.props.blockChain.customer?.canMintNewFactory || false
              }
              canMakeTestnetRequest={
                this.props.blockChain.customer?.canMakeTestnetRequest
              }
              factoryNftPrice={
                this.props.blockChain.customer?.appDataModel.pricesInfo
                  .factoryPrice
              }
              employeeNftPrice={
                this.props.blockChain.customer?.appDataModel.pricesInfo
                  .employeePrice
              }
            />
            <BuilderFilters
              filter={this.state.filter}
              onChange={(filter: Filter) => this.setState({ filter })}
              onClose={() => this.setState({ openFilters: false })}
              open={this.state.openFilters}
            />
            <BuilderFactoryGrid
              customerBalance={this.props.blockChain.customer?.tokenBalance}
              factoryNftPrice={this.props.blockChain.customer?.factoryPrice}
              employeeNFTPrice={this.props.blockChain.customer?.employeePrice}
              tokenAddress={this.props.blockChain.controller?.token?.address}
              filter={this.state.filter}
              factoryNfts={filteredNFTs}
              onChangeFactoriesPage={(page: number) =>
                this.props.onChangeFactoriesPagination(page)
              }
              page={this.props.factoriesPagination}
              nftsPerPage={8}
              onBurn={(id: number) => {
                if (this.props.blockChain.controller) {
                  this.resetModals();
                  this.props.onToggleLoader(true);
                  FactoryNFT.burnNFT(
                    id,
                    this.props.blockChain.controller,
                    (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    }
                  );
                } else {
                  this.props.onSetBlockChainError(AppErrorCode.BURN_ERROR);
                }
              }}
              onDeployEmployee={(factory: number) => {
                if (this.props.blockChain.controller) {
                  this.resetModals();
                  this.props.onToggleLoader(true);
                  EmployeeNFT.mintNFT(
                    factory,
                    this.props.blockChain.controller,
                    (error: AppErrorCode | null) => this.onUpdateData(error)
                  );
                } else {
                  this.props.onSetBlockChainError(AppErrorCode.MINT_ERROR);
                }
              }}
              onRestoreDeployments={(factory: number) => {
                if (this.props.blockChain.controller) {
                  this.resetModals();
                  this.props.onToggleLoader(true);
                  FactoryNFT.restoreDeploymentsNFT(
                    factory,
                    this.props.blockChain.controller,
                    (error: AppErrorCode | null) => this.onUpdateData(error)
                  );
                } else {
                  this.props.onSetBlockChainError(AppErrorCode.MINT_ERROR);
                }
              }}
            />
          </div>
          <div className="ct-employees">
            <h1 className="ct-text-center ct-text-light">Employees</h1>
            <BuilderEmployeesGrid
              employeeNfts={
                this.props.blockChain.customer?.employeeNFTArray || []
              }
              employeeNftPrice={this.props.blockChain.customer?.employeePrice}
              tokenAddress={this.props.blockChain.controller?.token?.address}
              onChangeEmployeesPage={(page: number) =>
                this.props.onChangeEmployeesPagination(page)
              }
              page={this.props.employeesPagination}
              nftsPerPage={8}
              onBurn={(id: number) => {
                if (this.props.blockChain.controller) {
                  this.props.onToggleLoader(true);
                  EmployeeNFT.burnNFT(
                    id,
                    this.props.blockChain.controller,
                    (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    }
                  );
                } else {
                  this.props.onSetBlockChainError(AppErrorCode.BURN_ERROR);
                }
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
