import { useState } from "react";
import { EmployeeNFT } from "../../../core/employee";
import { UtilsHelpers } from "../../../core/helpers/utils";

export interface StaticNFTProps {
  nft: EmployeeNFT;
  staticImage?: boolean;
  employeeNftPrice?: number;
  small?: boolean;
  className?: string;
  onTransfer?: (to: string) => void;
  onBurn?: () => void;
  onClick?: () => void;
}

enum NFTMessages {
  BURN = "burn",
  TRANSFER = "transfer",
}

export const StaticEmployeeNFT = (props: StaticNFTProps) => {
  const [actionMessage, changeMessage] = useState<NFTMessages | null>(null);

  let selectedMessage = null;

  switch (actionMessage) {
    case NFTMessages.BURN:
      selectedMessage = (
        <div className="ct-message">
          <p>
            Burn your employee. You will receive ({" "}
            {UtilsHelpers.parseMoney(props.nft.burnReward)} FCT ). The NFT will
            be transfer to burn address.
          </p>
          <button
            className="ct-main-button"
            onClick={() => {
              if (props.onBurn) props.onBurn();
              changeMessage(null);
            }}
          >
            Confirm
          </button>
        </div>
      );
      break;
    default:
      selectedMessage = null;
      break;
  }

  return (
    <div
      onClick={() => (props.onClick ? props.onClick() : null)}
      className={"ct-nft " + props.className + (props.small ? " ct-small" : "")}
    >
      <div className="ct-image">
        <img
          src={
            props.staticImage
              ? props.nft.uri
              : props.nft.uri + "&onlyImage=true"
          }
          alt=""
        />
      </div>
      <div className="ct-data">
        <span>
          <strong>ID: #</strong> {props.nft.id}
        </span>
        <span>
          <strong>Head: </strong> {UtilsHelpers.getTypeName(props.nft.head)}
        </span>
        <span>
          <strong>Body: </strong> {UtilsHelpers.getTypeName(props.nft.body)}
        </span>
        <span>
          <strong>Legs: </strong> {UtilsHelpers.getTypeName(props.nft.legs)}
        </span>
        <span>
          <strong>Hands: </strong> {UtilsHelpers.getTypeName(props.nft.hands)}
        </span>
        {props.nft.multiplier ? (
          <span>
            <strong>Multiplier: </strong> {props.nft.multiplier}
          </span>
        ) : (
          ""
        )}
        {!props.nft.canEmployeePlay ? (
          <span>
            <strong>Play in: </strong> {props.nft.playInTime.toFixed(0)} Hours
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="ct-actions-container">
        <div className="ct-actions">
          {props.onTransfer ? (
            <button
              className="ct-main-button"
              onClick={() => (props.onTransfer ? props.onTransfer("") : null)}
            >
              <span className="fas fa-send"></span>
            </button>
          ) : (
            " "
          )}
          {props.onBurn ? (
            <button
              className={
                "ct-main-button" +
                (actionMessage === NFTMessages.BURN ? " ct-active" : "")
              }
              onClick={() =>
                actionMessage === NFTMessages.BURN
                  ? changeMessage(null)
                  : changeMessage(NFTMessages.BURN)
              }
            >
              {actionMessage === NFTMessages.BURN ? (
                <span className="fas fa-times"></span>
              ) : (
                <span className="fas fa-trash"></span>
              )}
            </button>
          ) : (
            " "
          )}
        </div>
        <div className="ct-action-message">{selectedMessage}</div>
      </div>
    </div>
  );
};
