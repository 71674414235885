import { StaticNFTType } from "../../../core/nft";

import "./filters.styles.css";

export interface Filter {
  type: StaticNFTType | null;
}

export interface BuildersFiltersProps {
  open: boolean;
  filter: Filter;
  onChange: (filter: Filter) => void;
  onClose: () => void;
}

export const BuilderFilters = (props: BuildersFiltersProps) => {
  return (
    <div className={"ct-filters" + (props.open ? " ct-open" : "")}>
      <div className="ct-modal">
        <h1>
          Filter <span className="fas fa-times" onClick={() => props.onClose()}></span>
        </h1>
        <form>
          <div className="ct-selector">
            <label htmlFor="type">Type: </label>
            <select
              name="type"
              onChange={(e) => {
                props.onChange({
                  ...props.filter,
                  type:
                    e.target.selectedOptions[0].value === ""
                      ? null
                      : (Number(e.target.selectedOptions[0].value) as StaticNFTType),
                });
              }}
            >
              <option value={""}>- None -</option>
              <option value={StaticNFTType.NUCLEAR}>Nuclear</option>
              <option value={StaticNFTType.COAL}>Coal</option>
              <option value={StaticNFTType.FOOD}>Food</option>
              <option value={StaticNFTType.WOOD}>Wood</option>
              <option value={StaticNFTType.WATER}>Water</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};
