import React from "react";
import { AppErrorCode } from "../../core/app";
import { EmployeeNFT } from "../../core/employee";
import { FactoryNFT } from "../../core/nft";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { RelationsGameState } from "../../storage/state/game/state";
import { RelationsGame } from "../organisms/game/relations";

export interface RelationsGamePageProps {
  blockChain: BlockChainState;
  relationsGameState: RelationsGameState;
  gameChangeSelectedFactory: (factory: FactoryNFT | undefined) => void;
  gameAddSelectedEmployee: (employee: EmployeeNFT) => void;
  gameResetEmployeeSelection: () => void;
  gameRemoveEmployeeFromSelection: (employee: EmployeeNFT) => void;
  onLoadCustomerData: () => void;
  onSetBlockChainError: (error: AppErrorCode | null) => void;
  onToggleLoader: (force: boolean) => void;
}

export class RelationsGamePage extends React.Component<RelationsGamePageProps> {
  render() {
    return (
      <div className="ct-app-container">
        <RelationsGame
          gameChangeSelectedFactory={(factory: FactoryNFT | undefined) =>
            this.props.gameChangeSelectedFactory(factory)
          }
          gameAddSelectedEmployee={(employee: EmployeeNFT) =>
            this.props.gameAddSelectedEmployee(employee)
          }
          onToggleLoader={(force: boolean) => this.props.onToggleLoader(force)}
          onSetBlockChainError={(error: AppErrorCode | null) => {
            this.props.onSetBlockChainError(error);
          }}
          onLoadCustomerData={() => this.props.onLoadCustomerData()}
          gameResetEmployeeSelection={() =>
            this.props.gameResetEmployeeSelection()
          }
          gameRemoveEmployeeFromSelection={(employee: EmployeeNFT) =>
            this.props.gameRemoveEmployeeFromSelection(employee)
          }
          relationsGameState={this.props.relationsGameState}
          blockChain={this.props.blockChain}
        />
      </div>
    );
  }
}
