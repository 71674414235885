import { EmployeeNFT } from "../../../core/employee";
import { StaticEmployeeNFT } from "../../molecules/nft/employee";

import "./main.styles.css";

export interface EmployeesSelectorProps {
  selectedEmployees: EmployeeNFT[] | undefined;
  employees: EmployeeNFT[];
  gameAddSelectedEmployee: (employee: EmployeeNFT) => void;
  gameResetEmployeeSelection: () => void;
  gameRemoveEmployeeFromSelection: (employee: EmployeeNFT) => void;
}

export const EmployeeSelector = (props: EmployeesSelectorProps) => {
  return (
    <div className="ct-employee-selector-container ct-max-container">
      <h2>
        Choose your Employees{" "}
        <span onClick={() => props.gameResetEmployeeSelection()}>
          <span className="fas fa-redo-alt"></span> Reset
        </span>{" "}
      </h2>
      <small>Click on employee to select them</small>
      <div className="ct-employees">
        {props.employees.map((nft: EmployeeNFT, index) => {
          const isSelected = !!props.selectedEmployees?.find(
            (selected) => selected.id === nft.id
          );

          return (
            <StaticEmployeeNFT
              key={index}
              className={
                (nft.canEmployeePlay ? "ct-clickeable" : "ct-invalid") +
                (isSelected ? " ct-clicked" : "")
              }
              small
              nft={nft}
              onClick={() =>
                isSelected
                  ? props.gameRemoveEmployeeFromSelection(nft)
                  : props.gameAddSelectedEmployee(nft)
              }
            />
          );
        })}
      </div>
    </div>
  );
};
