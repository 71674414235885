import Web3 from "web3";
import { NavWindow } from "../../components/types";
import { AppChain, appConfig, AppMode, ChainData, posibleChains } from "../app";
import { UtilsHelpers } from "./utils";

export class BlockChainHelpers {
  static async loadWeb3(): Promise<boolean> {
    if ((window as NavWindow).ethereum) {
      (window as NavWindow).web3 = new Web3((window as NavWindow).ethereum);
      try {
        let accounts = await (window as NavWindow).ethereum.request({
          method: "eth_requestAccounts",
        });

        if (accounts.length > 0) return true;
        else throw new Error("Cannot connect to provider");
      } catch (error) {
        console.error("[BLOCK CHAIN] Principal error");
        return false;
      }
    } else if ((window as NavWindow).web3) {
      (window as NavWindow).web3 = new Web3(
        (window as NavWindow).web3.currentProvider
      );
      return true;
    } else {
      console.error(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );

      return false;
    }
  }

  static getProvider(): Web3 | null {
    return (window as NavWindow).web3
      ? ((window as NavWindow).web3 as Web3)
      : null;
  }

  static async addEthereumChain(chainData: ChainData) {
    await (window as NavWindow).ethereum.request({
      method: "wallet_addEthereumChain",
      params: [chainData],
    });
  }

  static getEthWindow() {
    return (window as NavWindow).ethereum
      ? (window as NavWindow).ethereum
      : null;
  }

  static async changeEthereumChain(chainId: string) {
    if (chainId) {
      return await (window as NavWindow).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } else return false;
  }

  static getAppChain(): ChainData {
    return appConfig.mode === AppMode.PRO
      ? posibleChains[AppChain.BSC]
      : appConfig.mode === AppMode.TEST
      ? posibleChains[AppChain.BSCT]
      : posibleChains[AppChain.GANACHE];
  }

  static async reloadOrChangeNetwork() {
    try {
      await BlockChainHelpers.changeEthereumChain(
        BlockChainHelpers.getAppChain().chainId
      );
    } catch (e) {
      let error: any = e;
      if (error?.code === 4902) {
        try {
          await BlockChainHelpers.addEthereumChain(
            BlockChainHelpers.getAppChain()
          );
        } catch (err) {
          UtilsHelpers.debugger("You cant change your network.");
        }
      }
    }
  }

  static parseTokenNumber(money: number): number {
    return money ? money / Math.pow(10, 18) : 0;
  }
}
