import "./footer.styles.css";

export const Footer = () => {
  return (
    <div className="ct-footer">
      <div className="ct-big-info-container ct-max-container">
        <div className="ct-big-info">
          <h4>Docs</h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://docs.businessbuilders.city"
          >
            Documentation
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://app.businessbuilders.city"
          >
            Production app
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://test.businessbuilders.city"
          >
            Test app
          </a>
        </div>
        <div className="ct-big-info">
          <h4>Network</h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/business_builders"
          >
            Telegram <span className="fab fa-telegram"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/NFTBBuilders"
          >
            Twitter <span className="fab fa-twitter"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/businessbuildersnft"
          >
            Instagram <span className="fab fa-instagram"></span>
          </a>
        </div>
        <div className="ct-token-footer">
          <p>
            <strong>FTC</strong>
          </p>
          <p>
            {" "}
            <a
              href="https://bscscan.com/address/0x5e03437d73425f2aab981e538d73296a70f11af4"
              target="_blank"
              rel="noopener noreferrer"
            >
              0x5e03437D73425F2aaB981E538D73296A70f11Af4
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
