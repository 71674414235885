import { AppErrorCode } from "./app";
import { BlockChain } from "./chain";
import { UtilsHelpers } from "./helpers/utils";
import { AppData } from "./types";

export interface EmployeeNFTTemplate {
  id: number;
  head?: number;
  body?: number;
  legs?: number;
  hands?: number;
  multiplier: number;
  uri?: string;
  canEmployeePlay?: boolean;
  employeePlayTime?: number;
}

export class EmployeeNFT implements EmployeeNFTTemplate {
  public id: number;
  public hands?: number;
  public head?: number;
  public body?: number;
  public legs?: number;
  public multiplier: number;
  public canEmployeePlay: boolean;
  public employeePlayTime: number;
  public uri: string;

  constructor(
    private _template: EmployeeNFTTemplate,
    public appDataModel: AppData
  ) {
    this.id = Number(this._template.id);
    this.hands = Number(this._template.hands);
    this.head = Number(this._template.head);
    this.body = Number(this._template.body);
    this.legs = Number(this._template.legs);
    this.canEmployeePlay = Boolean(this._template.canEmployeePlay);
    this.employeePlayTime = Number(this._template.employeePlayTime);
    this.multiplier = Number(this._template.multiplier);
    this.uri = this._template.uri || "";
  }

  static createWithContractTemplate(
    contractTemplate: { [id: string]: any },
    appDataModel: AppData
  ) {
    const employee = contractTemplate[0][0];

    return new EmployeeNFT(
      {
        id: contractTemplate.id,
        head: employee[0],
        body: employee[1],
        legs: employee[2],
        hands: employee[3],
        multiplier: employee[4],
        uri: contractTemplate[0][1],
        canEmployeePlay: contractTemplate[2],
        employeePlayTime: contractTemplate[1],
      },
      appDataModel
    );
  }

  get burnReward() {
    return (
      (Number(this.appDataModel.pricesInfo.employeePrice || 0) *
        Number(this.appDataModel.pageInfo.burnReward || 0)) /
      100
    );
  }

  get playInTime() {
    return (
      ((Date.now() -
        (this._template.employeePlayTime || 0) * 1000 -
        this.appDataModel.timesInfo.minPlayTime * 1000) /
        1000 /
        60 /
        60) *
      -1
    );
  }

  get parts() {
    return [this.head, this.body, this.legs, this.hands];
  }

  public static mintNFT(
    factory: number,
    blockChain: BlockChain,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint NFT");

    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + blockChain.selectedAccount + ")"
      );
      if (callback) blockChain.connections.mintEmployee(factory, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public static burnNFT(
    id: number,
    blockChain: BlockChain,
    callback: (error: AppErrorCode | null) => void
  ) {
    if (blockChain.selectedAccount && blockChain.connections) {
      UtilsHelpers.debugger("Try Burn NFT (" + id + ")");
      if (callback) blockChain.connections.burnEmployee(id, callback);
    } else {
      UtilsHelpers.debugger("Burn is not available.");
      if (callback) callback(AppErrorCode.BURN_ERROR);
    }
  }
}
