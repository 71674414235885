import { UtilsHelpers } from "../../../core/helpers/utils";
import "./actions.styles.css";

export interface BuilderActionsProps {
  onMint?: () => void;
  onGrowAll?: () => void;
  onToggleFilter?: () => void;
  onGetINV: () => void;
  growAllAmount?: number;
  canBuildNewNFT: boolean;
  factoryNftPrice: number | undefined;
  employeeNftPrice: number | undefined;
  disableBuy?: boolean;
  canMakeTestnetRequest?: boolean;
}

export const BuilderActions = (props: BuilderActionsProps) => {
  return (
    <div className="ct-builder-actions">
      {!props.disableBuy ? (
        <button
          className="ct-main-button"
          disabled={!props.canBuildNewNFT}
          onClick={() => (props.onMint ? props.onMint() : null)}
        >
          {props.canBuildNewNFT
            ? "Build new Factory (" + props.factoryNftPrice + " FCT)"
            : "Buy FCT to Build Factories."}
        </button>
      ) : (
        ""
      )}
      {props.onGrowAll ? (
        <button
          onClick={() => (props.onGrowAll ? props.onGrowAll() : null)}
          className="ct-main-button"
          disabled={!props.growAllAmount}
        >
          Grow All Factories (
          {UtilsHelpers.parseMoney(props.growAllAmount || 0)}) FCT
        </button>
      ) : (
        ""
      )}
      {!props.disableBuy ? (
        <button className="ct-main-button" disabled={true}>
          Buy new City
        </button>
      ) : (
        ""
      )}
      <a
        href="https://docs.businessbuilders.city/factories/building"
        target="_blank"
        rel="noopener noreferrer"
        className="ct-main-button"
      >
        <span className="fas fa-file-alt"></span>Documentation
      </a>
      {props.onToggleFilter ? (
        <button
          className="ct-main-button"
          onClick={() => (props.onToggleFilter ? props.onToggleFilter() : null)}
        >
          <span className="fas fa-search"></span>Search Factory
        </button>
      ) : (
        ""
      )}
      {props.canMakeTestnetRequest ? (
        <button className="ct-main-button" onClick={() => props.onGetINV()}>
          <span className="ct-icon fas fa-dollar-sign"></span>Request Tokens
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
