import React from "react";
import { EmployeeNFT } from "../../../../core/employee";
import { FactoryNFT } from "../../../../core/nft";
import { StaticCityNFT } from "../../nft/city";
import { StaticEmployeeNFT } from "../../nft/employee";
import "./main.styles.css";

export interface CitiesModalProps {
  open: boolean;
  nft?: FactoryNFT[];
  onClose: () => void;
}

export interface EmployeesModalProps {
  open: boolean;
  nft?: EmployeeNFT[];
  onClose: () => void;
}

export class FactoryNFTModal extends React.Component<CitiesModalProps> {
  render() {
    return (
      <div
        className={
          "ct-nft-modal ct-cities-modal" + (this.props.open ? " ct-open" : "")
        }
      >
        {this.props.nft && this.props.nft.length ? (
          <div className="ct-modal">
            <h1>
              New NFT{" "}
              <span
                className="fas fa-times"
                onClick={() => this.props.onClose()}
              ></span>
            </h1>
            {this.props.nft.map((nft, index) => {
              return <StaticCityNFT key={index} factoryNft={nft} />;
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export class EmployeeNFTModal extends React.Component<EmployeesModalProps> {
  render() {
    return (
      <div
        className={
          "ct-nft-modal ct-employees-modal" +
          (this.props.open ? " ct-open" : "")
        }
      >
        {this.props.nft && this.props.nft.length ? (
          <div className="ct-modal">
            <h1>
              New NFT{" "}
              <span
                className="fas fa-times"
                onClick={() => this.props.onClose()}
              ></span>
            </h1>
            {this.props.nft.map((nft, index) => {
              return <StaticEmployeeNFT key={index} nft={nft} />;
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
