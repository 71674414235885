import React from "react";
import { Link } from "react-router-dom";
import { AppErrorCode } from "../../../core/app";
import { EmployeeNFT } from "../../../core/employee";
import { FactoryNFT } from "../../../core/nft";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { RelationsGameState } from "../../../storage/state/game/state";
import { RelationsRewards } from "../../organisms/rewards/relations";
import { EmployeeSelector } from "../../organisms/selector/employees";
import { FactorySelector } from "../../organisms/selector/factories";

import "./relations.styles.css";

export interface RelationsGameProps {
  blockChain: BlockChainState;
  relationsGameState: RelationsGameState;
  gameChangeSelectedFactory: (factory: FactoryNFT | undefined) => void;
  gameAddSelectedEmployee: (employee: EmployeeNFT) => void;
  gameResetEmployeeSelection: () => void;
  gameRemoveEmployeeFromSelection: (employee: EmployeeNFT) => void;
  onLoadCustomerData: () => void;
  onSetBlockChainError: (error: AppErrorCode | null) => void;
  onToggleLoader: (force: boolean) => void;
}

export interface RelationsGamePropsState {
  playModal: boolean;
  playData: PlayData | null;
}

export interface PlayData {
  win: boolean;
  random: number;
  amount: number;
  chemistry: number;
}

export class RelationsGame extends React.Component<
  RelationsGameProps,
  RelationsGamePropsState
> {
  constructor(props: RelationsGameProps) {
    super(props);

    this.state = {
      playModal: false,
      playData: null,
    };
  }

  onPlayWithFactory(error: AppErrorCode | null, playData: PlayData | null) {
    if (error === null) {
      this.setState({ playModal: true, playData });
      this.props.gameResetEmployeeSelection();
      this.props.gameChangeSelectedFactory(undefined);
      this.props.onLoadCustomerData();
    } else this.props.onSetBlockChainError(error);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.playModal ? (
          <div className="ct-game-modal">
            <div className="ct-data-container">
              <h1>
                {this.state.playData?.win ? "Your winner" : "Your loser"}{" "}
                <span
                  className="fas fa-times"
                  onClick={() =>
                    this.setState({ playModal: false, playData: null })
                  }
                ></span>
              </h1>
              <h3>Total rewards: {this.state.playData?.amount || 0}</h3>
              <h3>Team chemestry: {this.state.playData?.chemistry || 0}</h3>
              <h3>Need chemestry: {this.state.playData?.random || 0}</h3>
              <p>
                Your team need to sleep. You should wait 24 hours to play with
                the same factory (If your factory in red you cant use).
              </p>
              <p>
                You can see your rewards on your factory (Game rewards){" "}
                <Link to="production">Click to see rewards</Link>{" "}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="ct-relations-game ct-max-container">
          <h1>Relations game</h1>
          <p>Get better relations, improve your business, earn tokens.</p>
          {this.props.blockChain.customer ? (
            this.props.blockChain.customer.factoryNFTArray.length > 0 ? (
              <React.Fragment>
                <FactorySelector
                  factories={this.props.blockChain.customer?.factoryNFTArray}
                  selectedFactory={
                    this.props.relationsGameState.selectedFactory
                  }
                  gameChangeSelectedFactory={(
                    factory: undefined | FactoryNFT
                  ) => {
                    this.props.gameChangeSelectedFactory(factory);
                    if (factory) this.props.gameResetEmployeeSelection();
                  }}
                />
                {this.props.relationsGameState.selectedFactory ? (
                  <EmployeeSelector
                    employees={this.props.blockChain.customer.employeeNFTArray}
                    selectedEmployees={
                      this.props.relationsGameState.employeeSelection
                    }
                    gameAddSelectedEmployee={(employee: EmployeeNFT) =>
                      this.props.gameAddSelectedEmployee(employee)
                    }
                    gameResetEmployeeSelection={() =>
                      this.props.gameResetEmployeeSelection()
                    }
                    gameRemoveEmployeeFromSelection={(employee: EmployeeNFT) =>
                      this.props.gameRemoveEmployeeFromSelection(employee)
                    }
                  />
                ) : (
                  ""
                )}
                {this.props.relationsGameState.selectedFactory ? (
                  <RelationsRewards
                    onPlay={() => {
                      if (
                        this.props.blockChain.controller &&
                        this.props.relationsGameState.selectedFactory?.id
                      ) {
                        this.props.onToggleLoader(true);
                        FactoryNFT.playWithFactory(
                          this.props.relationsGameState.selectedFactory?.id,
                          this.props.relationsGameState.employeeSelection.map(
                            (employee) => employee.id
                          ),
                          this.props.blockChain.controller,
                          (error, playData: PlayData | null) =>
                            this.onPlayWithFactory(error, playData)
                        );
                      }
                    }}
                    selectedEmployees={
                      this.props.relationsGameState.employeeSelection
                    }
                    selectedFactory={
                      this.props.relationsGameState.selectedFactory
                    }
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              <div className="ct-relations-without-factories">
                <h3>You need factories to play this game</h3>
                <Link to="/">Click to buy factories</Link>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
