import React from "react";
import { FactoryNFT } from "../../../core/nft";
import { StaticCityNFT } from "../../molecules/nft/city";
import { Filter } from "./filters";

import PancakeSwapLogo from "../../../assets/images/PANCAKESWAP.svg";

import { EmployeeNFT } from "../../../core/employee";
import { StaticEmployeeNFT } from "../../molecules/nft/employee";

import "./grid.styles.css";

export interface BuilderFactoryGridProps {
  factoryNfts: FactoryNFT[];
  nftsPerPage: number;
  filter: Filter;
  page: number;
  factoryNftPrice: number | undefined;
  employeeNFTPrice: number | undefined;
  tokenAddress: string | undefined;
  customerBalance: number | undefined;
  onChangeFactoriesPage: (page: number) => void;
  onTransfer?: (to: string) => void;
  onBurn?: (id: number) => void;
  onGrow?: (id: number) => void;
  onDeployEmployee?: (id: number) => void;
  onRestoreDeployments?: (id: number) => void;
}

export interface BuilderEmployeesGridProps {
  employeeNfts: EmployeeNFT[];
  nftsPerPage: number;
  page: number;
  employeeNftPrice: number | undefined;
  tokenAddress: string | undefined;
  onChangeEmployeesPage: (page: number) => void;
  onTransfer?: (to: string) => void;
  onBurn?: (id: number) => void;
}

export interface BuilderFactoryGridState {}
export interface BuilderEmployeesGridState {}

export class BuilderFactoryGrid extends React.Component<
  BuilderFactoryGridProps,
  BuilderFactoryGridState
> {
  render() {
    let selectedNFTs = [];
    let totalPages = Math.ceil(
      this.props.factoryNfts.length / this.props.nftsPerPage
    );

    for (let i = 0; i < totalPages; i++) {
      if (i === this.props.page) {
        for (
          let j = i * this.props.nftsPerPage;
          j < this.props.nftsPerPage * i + this.props.nftsPerPage;
          j++
        ) {
          if (this.props.factoryNfts[j])
            selectedNFTs.push(this.props.factoryNfts[j]);
        }
      }
    }

    return (
      <div className="ct-builder-grid">
        {totalPages > 0 ? (
          <div className="ct-grid-container">
            {selectedNFTs.map((nft, index) => {
              return (
                <StaticCityNFT
                  onTransfer={
                    this.props.onTransfer
                      ? (to: string) =>
                          this.props.onTransfer
                            ? this.props.onTransfer(to)
                            : null
                      : undefined
                  }
                  onBurn={
                    this.props.onBurn
                      ? () =>
                          this.props.onBurn && nft.id
                            ? this.props.onBurn(nft.id)
                            : null
                      : undefined
                  }
                  onGrow={
                    this.props.onGrow
                      ? () =>
                          this.props.onGrow && nft.id
                            ? this.props.onGrow(nft.id)
                            : null
                      : undefined
                  }
                  onDeployEmployee={
                    this.props.onDeployEmployee
                      ? () =>
                          this.props.onDeployEmployee && nft.id
                            ? this.props.onDeployEmployee(nft.id)
                            : null
                      : undefined
                  }
                  onResetDeployments={
                    this.props.onRestoreDeployments
                      ? () =>
                          this.props.onRestoreDeployments && nft.id
                            ? this.props.onRestoreDeployments(nft.id)
                            : null
                      : undefined
                  }
                  customerBalance={this.props.customerBalance}
                  key={index}
                  factoryNft={nft}
                  employeeNftPrice={this.props.employeeNFTPrice}
                />
              );
            })}
            <div className="ct-pagination">
              {this.props.page > 0 ? (
                <span
                  className="fas fa-chevron-left"
                  onClick={() => {
                    let momentPage: number = this.props.page;
                    if (momentPage > 0) momentPage--;
                    this.props.onChangeFactoriesPage(momentPage);
                  }}
                ></span>
              ) : (
                ""
              )}
              <span>{this.props.page + 1}</span>
              {this.props.page < totalPages - 1 ? (
                <span
                  className="fas fa-chevron-right"
                  onClick={() => {
                    let momentPage: number = this.props.page;
                    if (momentPage < totalPages - 1) momentPage++;
                    this.props.onChangeFactoriesPage(momentPage);
                  }}
                ></span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="ct-without-data">
            <p>
              You dont have any Factories. Build Factory with{" "}
              {this.props.factoryNftPrice} FCT Tokens
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=" +
                this.props.tokenAddress
              }
              className="ct-main-button"
            >
              Get FCT Tokens{" "}
              <img src={PancakeSwapLogo} alt="PancakeSwap logo" />
            </a>
          </div>
        )}
      </div>
    );
  }
}

export class BuilderEmployeesGrid extends React.Component<
  BuilderEmployeesGridProps,
  BuilderEmployeesGridState
> {
  render() {
    let selectedNFTs = [];
    let totalPages = Math.ceil(
      this.props.employeeNfts.length / this.props.nftsPerPage
    );

    for (let i = 0; i < totalPages; i++) {
      if (i === this.props.page) {
        for (
          let j = i * this.props.nftsPerPage;
          j < this.props.nftsPerPage * i + this.props.nftsPerPage;
          j++
        ) {
          if (this.props.employeeNfts[j])
            selectedNFTs.push(this.props.employeeNfts[j]);
        }
      }
    }

    return (
      <div className="ct-builder-grid ct-employees-grid">
        {totalPages > 0 ? (
          <div className="ct-grid-container">
            {selectedNFTs.map((nft, index) => {
              return (
                <StaticEmployeeNFT
                  onTransfer={
                    this.props.onTransfer
                      ? (to: string) =>
                          this.props.onTransfer
                            ? this.props.onTransfer(to)
                            : null
                      : undefined
                  }
                  onBurn={
                    this.props.onBurn
                      ? () =>
                          this.props.onBurn && nft.id
                            ? this.props.onBurn(nft.id)
                            : null
                      : undefined
                  }
                  key={index}
                  nft={nft}
                  employeeNftPrice={this.props.employeeNftPrice}
                />
              );
            })}
            <div className="ct-pagination">
              {this.props.page > 0 ? (
                <span
                  className="fas fa-chevron-left"
                  onClick={() => {
                    let momentPage: number = this.props.page;
                    if (momentPage > 0) momentPage--;
                    this.props.onChangeEmployeesPage(momentPage);
                  }}
                ></span>
              ) : (
                ""
              )}
              <span>{this.props.page + 1}</span>
              {this.props.page < totalPages - 1 ? (
                <span
                  className="fas fa-chevron-right"
                  onClick={() => {
                    let momentPage: number = this.props.page;
                    if (momentPage < totalPages - 1) momentPage++;
                    this.props.onChangeEmployeesPage(momentPage);
                  }}
                ></span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="ct-without-data">
            <p>
              You dont have any Employees. Deploy your employees with{" "}
              {this.props.employeeNftPrice} FCT Tokens
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=" +
                this.props.tokenAddress
              }
              className="ct-main-button"
            >
              Get FCT Tokens{" "}
              <img src={PancakeSwapLogo} alt="PancakeSwap logo" />
            </a>
          </div>
        )}
      </div>
    );
  }
}
